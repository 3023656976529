package net.ideablender.apofnj.dto

import kotlinx.datetime.Clock
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.Serializable

@Serializable
data class CoordinatesDTO(
    override val id: Int = 0,
    @Serializable(with = LocalDateTimeSerializer::class)
    override val dateCreated: LocalDateTime = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC),
    @Serializable(with = LocalDateTimeSerializer::class)
    override val dateUpdated: LocalDateTime = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC),
    val quoteRequest:DTOMin? = null,
    val latitude:Double = 0.0,
    val longitude:Double = 0.0,
    val msg:String = ""
): DTO{
    companion object{
        fun getPath() = "/addressLookup"
    }
}