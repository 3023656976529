import io.ktor.client.*
import io.ktor.client.features.*
import io.ktor.client.features.json.*
import io.ktor.client.features.json.serializer.*
import io.ktor.client.request.*
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import net.ideablender.apofnj.Home
import net.ideablender.apofnj.dto.PhotoDTO
import net.ideablender.apofnj.helpers.CommonHelpers
import net.ideablender.apofnj.pojo.ModelPageState
import net.ideablender.apofnj.statics.KEY_IMAGES
import net.ideablender.apofnj.store.*
import react.dom.render

val jsonClient = HttpClient {
    install(JsonFeature) { serializer = KotlinxSerializer() }
    defaultRequest {
        header("Access-Control-Allow-Origin", "*")
    }
}

val crScrope = MainScope()

val endpoint = if(window.location.origin.contains("localhost") ){
    "http://localhost:8080/"
}else{
    window.location.origin
}

fun getPathForImage(dto:PhotoDTO) = "${endpoint}$KEY_IMAGES/${dto.fileName}"
fun getPathForThumb(dto:PhotoDTO):String{
    val retval = "${endpoint}$KEY_IMAGES/${CommonHelpers.generateThumbFileName(dto.fileName)}"
    return retval
}

fun buildHeader(clazz:String, mps: ModelPageState):String{
    return when(mps){
        ModelPageState.DEFAULT, ModelPageState.VIEW -> {
            clazz
        }
        ModelPageState.EDIT -> {
            "$clazz > Edit"
        }
        ModelPageState.CREATE -> {
            "$clazz > Create"
        }
    }
}

fun Long.toDateTime() = Instant.fromEpochMilliseconds(this).toLocalDateTime(TimeZone.UTC)

fun Long.toDateTimeFormatted():String{
    val dt = this.toDateTime()
    return "${dt.monthNumber} / ${dt.dayOfMonth} / ${dt.year}  ${dt.hour}:${dt.minute}"
}


fun main() {
    window.onload = {
        crScrope.launch{
            TagService.fetchAll()
            PhotoService.fetchAll()
            GalleryService.fetchAll()
            QuoteRequestService.fetchAll()
            Store.fetchProps()
        }
        render(document.getElementById("root")) {
            child(Home::class) {

            }
        }
    }
}

//val parsed = window.location.hash.replaceBefore(STUB_PASSWORD_RESET, "").replace(STUB_PASSWORD_RESET, "").replaceFirst("=", "")
fun pullStubDataFromUrl(url:String, stub:String) = url.replaceBefore(stub, "").replace(stub, "").replaceFirst("=", "")
