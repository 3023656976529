package net.ideablender.apofnj.req

import kotlinx.datetime.LocalDateTime
import kotlinx.serialization.Contextual
import kotlinx.serialization.Serializable

@Serializable
data class PhotoREQ(
    override var id:Int? = 0,
    var fileName:String? = null,
    var header:String? = null,
    var title:String? = null,
    var description:String? = null,
    var base64Photo:String? = null,
    var base64Thumb:String? = null,
    var galleryId:Int? = null,
    @Contextual
    var dateTaken:LocalDateTime?  = null,
    var tags:List<String> = mutableListOf(),
) : REQ {
    override fun getDisplay(): String  = "GalleryREQ(fileName:$fileName, header:$header, description:$description, , base64: TOO LARGE TO DISPLAY, galleryId:$galleryId, tags:$tags )"
}