package net.ideablender.apofnj.page.photo

import getPathForImage
import getPathForThumb
import kotlinx.html.ButtonType
import kotlinx.html.js.onClickFunction
import net.ideablender.apofnj.common.*
import net.ideablender.apofnj.pojo.ModelPageState
import net.ideablender.apofnj.store.PhotoService
import net.ideablender.apofnj.store.Store
import react.RBuilder
import react.RComponent
import react.RProps
import react.RState
import react.dom.*
import toDateTimeFormatted

interface PhotoDetailState : RState {
}

interface PhotoDetailProps : RProps {
    var changePageState: (pageState:ModelPageState, id:Int) -> Unit
}

class PhotoDetail : RComponent<PhotoDetailProps, PhotoDetailState>() {
    override fun PhotoDetailState.init() {
    }

    override fun RBuilder.render() {
        val dto = PhotoService.getChosenItem()
        if(dto != null){
            card(cardWrapperCss = "PhotoDetail") {
                iRow{
                    iDetailLabel(value = "Title")
                    iDetailValue(value = dto.title)
                }
                iRow{
                    iDetailLabel(value = "Header")
                    iDetailValue(value = dto.header)
                }
                iRow{
                    iDetailLabel(value = "Description")
                    iDetailValue(value = dto.description)
                }
                iRow{
                    iDetailLabel(value = "File Name")
                    iDetailValue(value = dto.fileName)
                }
                iRow{
                    iDetailLabel(value = "Date Taken")
                    iDetailValue(value = dto.dateTaken.toDateTimeFormatted())
                }
                iRow{
                    iDetailLabel(value = "Gallery")
                    iDetailValue(value = dto.gallery.detail)
                }
                iRow{
                    console.log(dto.tags)
                    iDetailLabel(value = "Tags")

                    dto.tags.forEach {
                        console.log(it)
                        displayTagHolder(it)
                    }

                }
                dtoSummarySection(dto)
                hr{}
                iRow {
                       iCol("buttonRow"){
                        button(classes = "btn btn-warning", type = ButtonType.button){
                            attrs.onClickFunction = {
                                Store.setMPS(ModelPageState.EDIT)
                                PhotoService.setChosenId(dto.id)
                                it.stopPropagation()
                                it.preventDefault()
                            }
                            span("fas fa-edit") {}
                            +"Update"
                        }
                        button(classes = "btn btn-danger", type = ButtonType.button){
                            span("fas fa-trash") {}
                            +"Delete"
                        }
                    }
                }
            }
            card(headerTxt = "Full Image"){
                iRow{
                    div("col-12"){
                        child(FullSizePhotoWrapper::class){
                            attrs.photoUrl = getPathForImage(dto)
                        }
                    }
                }
            }
            card(headerTxt = "Thumb Image"){
                iRow{
                    div("col-12"){
                        img(src =getPathForThumb(dto)) {  }
                    }
                }
            }
        }
    }
}