package net.ideablender.apofnj.dto

import kotlinx.datetime.LocalDateTime
import kotlinx.serialization.Serializable


@Serializable
data class PhotoDTO(
    override val id: Int,
    @Serializable(with = LocalDateTimeSerializer::class)
    override val dateCreated: LocalDateTime,
    @Serializable(with = LocalDateTimeSerializer::class)
    override val dateUpdated: LocalDateTime,
    val dateTaken:Long,
    val fileName:String,
    val title:String,
    val header:String,
    val description:String,
    val gallery:DTOMin,
    val tags:List<DTOMin>,
    val widthFull:Int,
    val widthThumb:Int,
    val heightFull:Int,
    val heightThumb:Int
) : DTO {
    companion object{
        fun getRestPath(): String = "/photo"
    }
}