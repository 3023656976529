package net.ideablender.apofnj.req

import net.ideablender.apofnj.pojo.RoleType

class UserREQ(
    var roleType: String? = null,
    var nameFirst: String? = null,
    var nameLast: String? = null,
    var email: String? = null,
    var password: String? = null,
    override var id: Int? = 0
) : REQ {
    override fun getDisplay(): String = " Should not be needed for AddressRequest"
}