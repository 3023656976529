package net.ideablender.apofnj.dto

import kotlinx.datetime.LocalDateTime
import kotlinx.serialization.Serializable

@Serializable
data class UserDTO(
    override val id: Int,
    @Serializable(with = LocalDateTimeSerializer::class)
    override val dateCreated: LocalDateTime,
    @Serializable(with = LocalDateTimeSerializer::class)
    override val dateUpdated: LocalDateTime,
    val roleType:String,
    val status:String,
    val nameFirst:String,
    val nameLast:String,
    val email:String,

    ) : DTO {
    companion object{
        fun getRestPath(): String = "/user"
    }
}