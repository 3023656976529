package net.ideablender.apofnj.page.tags

import crScrope
import kotlinx.browser.window
import kotlinx.coroutines.launch
import kotlinx.html.js.onClickFunction
import net.ideablender.apofnj.common.SortDirection
import net.ideablender.apofnj.common.TableSort
import net.ideablender.apofnj.dto.TagDTO
import net.ideablender.apofnj.page.photo.TableColumn
import net.ideablender.apofnj.pojo.ModelPageState
import net.ideablender.apofnj.req.DeleteREQ
import net.ideablender.apofnj.store.PhotoService
import net.ideablender.apofnj.store.Store
import net.ideablender.apofnj.store.TagService
import react.*
import react.dom.*

interface TagTableState : RState {
    var chosenTag:String?
    var sortDirection:SortDirection
    var tableColumn: TagTableColumn
    var tags :List<TagDTO>?
}

interface TagTableProps : RProps {
    var items:List<TagDTO>?
}
enum class TagTableColumn(override val description:String) : TableColumn {
    _NAME("Name"),
    PHOTO_COUNT("Photo Count")
}

class TagTable : RComponent<TagTableProps, TagTableState>() {
    override fun TagTableState.init() {
        sortDirection = SortDirection.DOWN
        tableColumn = TagTableColumn._NAME
    }

    override fun componentDidMount() {
        PhotoService.registerCallback("TagTable", ::onStoreChange)
        setState {
            tags = props.items
        }
    }

    override fun componentWillUnmount() {
        PhotoService.unRegisterCallback("TagTable")
    }

    private fun onStoreChange(){
        setState {
            tags = TagService.getAll()
        }
    }

    private fun onRowClick(item:TagDTO, selected: Boolean){
        if(selected){
            setState {
                chosenTag = null
            }
        }else{
            setState {
                chosenTag = item.name
            }
        }
    }

    private fun onTableSortClick(_column:TableColumn, _direction: SortDirection){
        setState {
            sortDirection = _direction
            tableColumn = _column as TagTableColumn
            tags = when(state.sortDirection){
                SortDirection.UP -> {
                    when(state.tableColumn){
                        TagTableColumn._NAME -> state.tags!!.sortedBy {it.name.toLowerCase()}
                        TagTableColumn.PHOTO_COUNT -> state.tags!!.sortedBy { it.photos.count() }
                    }

                }
                SortDirection.DOWN -> {
                    when(state.tableColumn){
                        TagTableColumn._NAME -> state.tags!!.sortedByDescending { it.name.toLowerCase() }
                        TagTableColumn.PHOTO_COUNT -> state.tags!!.sortedByDescending { it.photos.count() }
                    }
                }
            }
        }
    }

    private fun getConfirmMessage(dto: TagDTO) = "Are you sure you want to delete tag ${dto.name}?"

    override fun RBuilder.render() {
        table(classes = "ModelTable table table-striped table-bordered table-sm table-hover") {
            thead("thead-dark") {
                tr {
                    th {
                        child(TableSort::class){
                            attrs.tableColumn = TagTableColumn._NAME
                            attrs.onArrowClick = :: onTableSortClick
                            attrs.currColumn = state.tableColumn
                            attrs.currDirection = state.sortDirection
                        }
                    }
                    th{
                        child(TableSort::class){
                            attrs.tableColumn = TagTableColumn.PHOTO_COUNT
                            attrs.onArrowClick = :: onTableSortClick
                            attrs.currColumn = state.tableColumn
                            attrs.currDirection = state.sortDirection
                        }
                    }
                    th{}
                }
            }
            tbody {
                if(state.tags != null){
                    state.tags!!.forEach { item ->
                        val selected = state.chosenTag == item.name
                        val rowClass = if(selected) "rowSelected" else ""
                        tr(classes = rowClass){
                            attrs.onClickFunction = {
                                onRowClick(item, selected)
                            }
                            td("noWrap") {
                                +item.name
                            }
                            td{
                                +item.photos.count().toString()
                            }
                            td("noWrap"){
                                span("fas fa-image") {
                                    attrs.onClickFunction = {
                                        Store.setMPS(ModelPageState.VIEW)
                                        TagService.setChosenId(item.id)
                                        it.stopPropagation()
                                        it.preventDefault()
                                    }
                                }
                                span("fas fa-trash") {
                                    attrs.onClickFunction = {
                                        it.preventDefault()
                                        if(window.confirm(getConfirmMessage(item))){
                                            console.log("delete")
                                            setState {
                                                chosenTag = null
                                            }
                                            crScrope.launch {
                                                TagService.delete(DeleteREQ(item.id))
                                            }
                                        }else{
                                            console.log("DO NOT DELTE")
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}