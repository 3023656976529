package net.ideablender.apofnj.page.tags

import crScrope
import kotlinx.coroutines.launch
import kotlinx.html.ButtonType
import kotlinx.html.js.onClickFunction
import net.ideablender.apofnj.common.*
import net.ideablender.apofnj.exception.ReqNotInitializedException
import net.ideablender.apofnj.pojo.ModelPageState
import net.ideablender.apofnj.req.TagREQ
import net.ideablender.apofnj.store.PhotoService
import net.ideablender.apofnj.store.Store
import net.ideablender.apofnj.store.TagService
import react.*
import react.dom.button
import react.dom.form
import react.dom.hr
import react.dom.span

interface TagFormState : RState {
    var req: TagREQ?
    var dirty: Boolean
    var errMsg:String?

}

interface TagFormProps : RProps {
    var pageState: ModelPageState
}

class TagForm : RComponent<TagFormProps, TagFormState>() {
    val clazz: String = "TagForm"
    override fun TagFormState.init() {
        dirty = false
    }

    override fun componentDidMount() {
        setState {
            req = TagREQ(id = null, name = null)
        }
    }


    private fun onInputChange(fd: FormData) {
        var tempReq = state.req ?: throw ReqNotInitializedException(clazz)
        var _dirty = false
        when (fd.param) {
            "name" -> {
                tempReq = tempReq.copy(name = fd.value as? String)
                _dirty = true
            }
        }
        setState {
            req = tempReq
            dirty = _dirty
        }
    }


    private fun canISubmit():Boolean{
        if(state.req !== null && state.dirty == true){
            return true
        }
        return false
    }

    override fun RBuilder.render() {
        val dto = PhotoService.getChosenItem()
        val mps = Store.getMPS()
        if (state.req != null) {
            val _req = state.req ?: throw ReqNotInitializedException(clazz)
            card(cardWrapperCss = "PhotoForm") {
                form {
                    iFormRow{
                        iFormLabel(value = "Name")
                        iInputWrapper{
                            iInput(_value = _req.name, _name = "name", onInputChange = ::onInputChange)
                        }
                    }
                    iRow {
                        iCol("buttonRow"){
                            button(classes = "btn btn-success", type = ButtonType.button){
                                attrs.disabled = canISubmit() == false
                                attrs.onClickFunction = {
                                    val req = state.req!!
                                    crScrope.launch {
                                        TagService.addTagToAllPhotos(req)
                                    }
                                }
                                span("fa fa-plus-square") {}
                                +"Create"
                            }
                        }
                    }
                }
                hr{}
            }
        }
    }
}
