package net.ideablender.apofnj.page.photo

import crScrope
import getPathForImage
import kotlinx.coroutines.launch
import kotlinx.html.ButtonType
import kotlinx.html.InputType
import kotlinx.html.js.onChangeFunction
import kotlinx.html.js.onClickFunction
import net.ideablender.apofnj.common.*
import net.ideablender.apofnj.dto.DTOMin
import net.ideablender.apofnj.exception.FormViewedWrongPageState
import net.ideablender.apofnj.exception.NoChosenEntityForClazz
import net.ideablender.apofnj.exception.ReqNotInitializedException
import net.ideablender.apofnj.pojo.DocumentType
import net.ideablender.apofnj.pojo.ModelPageState
import net.ideablender.apofnj.req.PhotoREQ
import net.ideablender.apofnj.statics.NAV_KEY_PHOTOS
import net.ideablender.apofnj.statics.NAV_KEY_TAGS
import net.ideablender.apofnj.store.GalleryService
import net.ideablender.apofnj.store.PhotoService
import net.ideablender.apofnj.store.Store
import net.ideablender.apofnj.store.TagService
import org.w3c.dom.HTMLTextAreaElement
import org.w3c.dom.events.Event
import org.w3c.files.File
import org.w3c.files.FileReader
import react.*
import react.dom.*
import react.router.dom.routeLink

interface PhotoFormState : RState {
    var req: PhotoREQ?
    var dirty: Boolean
    var newTags:String
    var errMsg:String?
    var photoFile: File?
    var thumbFile: File?
}

interface PhotoFormProps : RProps {
    var pageState: ModelPageState
}

class PhotoForm : RComponent<PhotoFormProps, PhotoFormState>() {
    val clazz: String = "PhotoForm"
    override fun PhotoFormState.init() {
        dirty = false
        newTags = ""
    }

    override fun componentDidMount() {
        setState {
            req = initReq()
        }
    }

    private fun initReq(): PhotoREQ {
        return when (props.pageState) {
            ModelPageState.DEFAULT,ModelPageState.VIEW -> {
                throw FormViewedWrongPageState(clazz, props.pageState)
            }
            ModelPageState.EDIT -> {
                val chosen = PhotoService.getChosenItem() ?: throw NoChosenEntityForClazz(clazz)
                PhotoService.dtoToReq(chosen)
            }
            ModelPageState.CREATE -> {
                PhotoREQ(header = "", description = "", title = "")
            }
        }
    }

    private fun onInputChange(fd: FormData) {
        var tempReq = state.req ?: throw ReqNotInitializedException(clazz)
        var _dirty = false
        when (fd.param) {
            "description" -> {
                tempReq = tempReq.copy(description = fd.value as? String)
                _dirty = true
            }
            "header" -> {
                tempReq = tempReq.copy(header = fd.value as? String)
                _dirty = true
            }
            "title" -> {
                tempReq = tempReq.copy(title = fd.value as? String)
                _dirty = true
            }
            "galleryId" -> {
                if(fd.value != 0){
                    tempReq = tempReq.copy(galleryId = fd.value)
                    _dirty = true
                }
            }
        }
        setState {
            req = tempReq
            dirty = _dirty
        }
    }

    private fun onNewTagChange(_newTags:String) {
        setState {
            newTags = _newTags
        }
    }

    private fun removeTag(_tag:String){
        val _req = state.req ?: throw ReqNotInitializedException(clazz)
        val newTagList =_req.tags.toMutableList()
        newTagList.remove(_tag)
        setState {
            req = _req.copy(tags = newTagList)
        }
    }

    private fun handleButtonClick(evt: Event){
        evt.preventDefault()
        val tagSet = state.req!!.tags.toMutableSet()
        val newTagList = state.newTags.split(",").map{it.trim().toLowerCase()}
        newTagList.forEach {newTag ->
            tagSet.add(newTag)
        }
        val _req = state.req ?: throw ReqNotInitializedException(clazz)
        setState {
            req = _req.copy(tags = tagSet.toList())
            newTags = ""
        }
    }

    private fun isFileValid(f: File): Boolean {
        if (f.type == DocumentType.JPG.type) {
            return true
        } else {
            setState {
                errMsg = "Your profile image must be a jpeg"
            }
            return false
        }
    }

    private fun onFileChange(event: dynamic) {
        event.preventDefault()
        val reader = FileReader()
        val tempFile: File = event.target.files[0]
        reader.onload = {
            val fileValid = isFileValid(tempFile)
            if(fileValid == true){
                setState {
                    photoFile = tempFile
                    errMsg = null
                }
                docLoaded(FormData(label = "avatarImg", value = reader.result, param = "avatarImg"))
            }
        }
        reader.readAsDataURL(tempFile)
    }

    private fun onThumbChange(event: dynamic) {
        event.preventDefault()
        val reader = FileReader()
        val tempFile: File = event.target.files[0]
        reader.onload = {
            val fileValid = isFileValid(tempFile)
            if(fileValid == true){
                setState {
                    thumbFile = tempFile
                    errMsg = null
                }
                thumbLoaded(FormData(label = "avatarImg", value = reader.result, param = "avatarImg"))
            }
        }
        reader.readAsDataURL(tempFile)
    }

    private fun docLoaded(fd: FormData) {
        setState {
            req = state.req!!.copy(base64Photo = fd.value)
        }
    }

    private fun thumbLoaded(fd: FormData) {
        setState {
            req = state.req!!.copy(base64Thumb = fd.value)
        }
    }

    private fun canISubmit():Boolean{
        if(state.req !== null && state.req!!.base64Photo != null && state.req!!.base64Thumb != null && state.req!!.galleryId != null){
            return true
        }
        return false
    }


    override fun RBuilder.render() {
        val dto = PhotoService.getChosenItem()
        val mps = Store.getMPS()
        if (state.req != null) {
            val _req = state.req ?: throw ReqNotInitializedException(clazz)
            card(cardWrapperCss = "PhotoForm") {
                form {
                    if(dto != null){
                        div("form-group row") {
                            iFormLabel(value = "File Name")
                            iInputWrapper{
                                iInput(_value = dto.fileName, _name = "fileName", onInputChange = ::onInputChange, _readOnly = true)
                            }
                        }
                        hr{}
                    }
                    if(mps == ModelPageState.CREATE){
                        iFormRow{
                            iFormLabel(value = "Full Photo")
                            iInputWrapper{
                                if(state.photoFile == null){
                                    div("input-group"){
                                        div("custom-file"){
                                            input(classes = "custom-file-input", type = InputType.file, name = "header") {
                                                attrs.onChangeFunction = { e ->
                                                    onFileChange(e)
                                                }
                                            }
                                            label("custom-file-label") {
                                                +""
                                            }
                                        }
                                    }
                                }else{
                                    div("input-group"){
                                        input(classes = "form-control", type = InputType.text, name = "header") {
                                            attrs.value = state.photoFile!!.name ?: ""
                                            attrs.readonly = true
                                        }
                                        div(classes = "input-group-append"){
                                            button(classes = "btn btn-danger", type = ButtonType.button) {
                                                attrs.onClickFunction = {
                                                    setState {
                                                        photoFile = null
                                                        req = state.req!!.copy(base64Photo = null)
                                                    }
                                                }
                                                +"Delete"
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        iFormRow{
                            iFormLabel(value = "Thumb Photo")
                            iInputWrapper{
                                if(state.thumbFile == null){
                                    div("input-group"){
                                        div("custom-file"){
                                            input(classes = "custom-file-input", type = InputType.file, name = "header") {
                                                attrs.onChangeFunction = { e ->
                                                    onThumbChange(e)
                                                }
                                            }
                                            label("custom-file-label") {
                                                +""
                                            }
                                        }
                                    }
                                }else{
                                    div("input-group"){
                                        input(classes = "form-control", type = InputType.text, name = "header") {
                                            attrs.value = state.thumbFile!!.name ?: ""
                                            attrs.readonly = true
                                        }
                                        div(classes = "input-group-append"){
                                            button(classes = "btn btn-danger", type = ButtonType.button) {
                                                attrs.onClickFunction = {
                                                    setState {
                                                        thumbFile = null
                                                        req = state.req!!.copy(base64Thumb = null)
                                                    }
                                                }
                                                +"Delete"
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    iFormRow{
                        iFormLabel(value = "Title")
                        iInputWrapper{
                            iInput(_value = _req.title, _name = "title", onInputChange = ::onInputChange)
                        }
                    }
                    iFormRow{
                        iFormLabel(value = "Header")
                        iInputWrapper{
                            iInput(_value = _req.header, _name = "header", onInputChange = ::onInputChange)
                        }
                    }
                    iFormRow{
                        iFormLabel(value = "Description")
                        iInputWrapper{
                            textArea(classes = "form-control") {
                                attrs.value = _req.description ?: ""
                                attrs.maxLength = "250"
                                attrs.name = "description"
                                attrs.onChangeFunction = {
                                    val target = it.target as HTMLTextAreaElement
                                    onInputChange(
                                        FormData(
                                            label = "description",
                                            value = target.value.take(250),
                                            param = "description"
                                        )
                                    )
                                }
                            }
                        }
                    }
                    iFormRow{
                        iFormLabel(value = "Category")
                        iInputWrapper{
                            val optionValueList : MutableList<OptionValue> = mutableListOf()
                            if(_req.galleryId == null){
                                optionValueList.add(OptionValue(0.toString(), "Choose One"))
                            }
                            GalleryService.getAll().forEach {
                                optionValueList.add(OptionValue(value = it.id.toString(), it.name))
                            }
                            iSelect(_value = _req.galleryId?.toString() ?: 0.toString(), _name = "galleryId", optionValueList = optionValueList,onInputChange = ::onInputChange)
                        }
                    }
                    iFormRow{
                        iFormLabel(value = "Current Tags")
                        iInputWrapper("tagHolderWrapper"){
                            _req.tags.forEach { tagString ->
                                formTagHolder(tagString, ::removeTag)
                            }
                        }
                    }
                    hr{}
                    p{
                        +"Add tags via comma separation and clicking the add tags button."
                    }
                    iFormRow{
                        iFormLabel(value = "Add Tags")
                        iInputWrapper{
                            div("input-group"){
                                input(classes = "form-control", type = InputType.text, name = "header") {
                                    attrs.value = state.newTags ?: ""
                                    attrs.onChangeFunction = {
                                        onNewTagChange(FormData.eventToFormData(it).value as String)
                                    }
                                }
                                div(classes = "input-group-append"){
                                    button(classes = "btn btn-success", type = ButtonType.button) {
                                        attrs.disabled = state.newTags.length < 1
                                        attrs.onClickFunction = {
                                            handleButtonClick(it)
                                        }
                                        +"Add Tags"
                                    }
                                }
                            }
                        }
                    }
                    hr{

                    }
                    iRow {
                        iCol("buttonRow"){
                            if(mps == ModelPageState.EDIT){
                                button(classes = "btn btn-warning", type = ButtonType.button){
                                    attrs.onClickFunction = {
                                        val req = state.req!!
                                        crScrope.launch {
                                            PhotoService.update(req)
                                        }
                                    }
                                    span("fas fa-edit") {}
                                    +"Update"
                                }
                            }else{
                                button(classes = "btn btn-success", type = ButtonType.button){
                                    attrs.disabled = canISubmit() == false
                                    attrs.onClickFunction = {
                                        val req = state.req!!.copy(fileName = state.photoFile!!.name,
                                            base64Thumb = state.req!!.base64Thumb!!.replace("data:image/jpeg;base64,", ""),
                                            base64Photo = state.req!!.base64Photo!!.replace("data:image/jpeg;base64,", "")
                                        )

                                        crScrope.launch {
                                            PhotoService.create(req)
                                        }
                                    }
                                    span("fa fa-plus-square") {}
                                    +"Create"
                                }
                            }
                        }
                    }
                }
                hr{}
                if( dto != null && Store.getMPS() == ModelPageState.EDIT){
                    iRow{
                        div("col-12"){
                            if(Store.getMPS() == ModelPageState.EDIT){
                                img(src = getPathForImage(dto), classes = "responsive") {  }
                            }
                        }
                    }
                }
                if(Store.getMPS() == ModelPageState.CREATE){
                    if(state.photoFile != null){
                        card(headerTxt = "Full Image"){
                            img(src = state.req!!.base64Photo, classes = "responsive") {  }
                        }
                    }

                    if(state.thumbFile != null){
                        card(headerTxt = "Thumb Image"){
                            img(src = state.req!!.base64Thumb) {  }
                        }
                    }
                }
            }
        }
    }
}

fun RBuilder.formTagHolder(tag:String, onClick: (String)->Unit){
    div(classes = "formTagHolder"){
        span("theTag"){
            +tag
        }
        span(classes = "fas fa-trash"){
            attrs.onClickFunction = {
                onClick(tag)
            }
        }
    }
}

fun RBuilder.displayPhotoTagHolder(dto:DTOMin){
    div(classes = "displayTagHolder") {
        span("theTag") {
            routeLink(to = NAV_KEY_PHOTOS) {
                +dto.detail
            }
            attrs.onClickFunction = {
                Store.setMPS(ModelPageState.VIEW)
                PhotoService.setChosenId(dto.id)
            }
        }
    }
}

fun RBuilder.displayTagHolder(dto:DTOMin){
        div(classes = "displayTagHolder"){
            span("theTag"){
                routeLink(to = NAV_KEY_TAGS) {
                    +dto.detail
                }
                attrs.onClickFunction = {
                    Store.setMPS(ModelPageState.VIEW)
                    TagService.setChosenId(dto.id)
                }
            }
        }


}