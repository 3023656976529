package net.ideablender.apofnj.req

import kotlinx.serialization.Serializable
import net.ideablender.apofnj.dto.CoordinatesDTO

@Serializable
data class JobREQ(
    var companyName:String? = null,
    var siteName:String? = null,
    var siteDescription:String? = null,
    var siteStreet:String? = null,
    var siteCity:String? = null,
    var siteState:String? = null,
    var siteCoords:MutableList<CoordinatesDTO> = mutableListOf(),
    var contactEmail:String? = null,
    var contactPhone:String? = null,
    var contactNameFirst:String? = null,
    var contactNameLast:String? = null,
    override var id: Int? = null
) :REQ{
    companion object{
        fun getPath() = "/newJob"
    }

    override fun getDisplay() = "${contactEmail} - ${siteStreet}"
}
