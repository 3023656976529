package net.ideablender.apofnj.store

import endpoint
import io.ktor.client.request.*
import io.ktor.http.*
import jsonClient
import kotlinx.browser.window
import net.ideablender.apofnj.buildApiLink
import net.ideablender.apofnj.dto.*
import net.ideablender.apofnj.exception.MissingMandatoryFieldException
import net.ideablender.apofnj.pojo.ModelPageState
import net.ideablender.apofnj.req.*
import net.ideablender.apofnj.statics.KEY_RESET_PASSWORD
import net.ideablender.apofnj.statics.KEY_RUN_QR_REPORT

abstract class Observed {
    val cbMap: MutableMap<String, () -> Unit> = mutableMapOf()

    fun registerCallback(key: String, cb: () -> Unit) {
        cbMap[key] = cb
    }

    fun unRegisterCallback(key: String) {
        cbMap.remove(key)
    }

    fun invokeCbs() {
        cbMap.forEach {
            it.value.invoke()
        }
    }
}

object Store :Observed(){
    private var _modelPageState: ModelPageState = ModelPageState.DEFAULT
    private var serviceLoaded:MutableSet<String> = mutableSetOf()
    private var loginToken:String? = null
    private var props:PropsDTO? = null

     suspend fun fetchProps() {
        val resp = jsonClient.get<PropsDTO>(endpoint + "/api" + PropsDTO.getRestPath())
        props = resp
         console.log(resp)
         invokeCbs()

    }
    fun getProps() = props

    fun isUserLoggedIn()  = loginToken != null

    fun setAppLoaded(service:String){
        serviceLoaded.add(service)
        if(serviceLoaded.size == 3){
            invokeCbs()
        }
    }

    fun getToken() = loginToken
    fun setToken(tok:String){
        loginToken = tok
        invokeCbs()
    }

    fun getAppLoaded():Boolean{
        return serviceLoaded.size == 3
    }

    fun setMPS(mps: ModelPageState){
        _modelPageState = mps
        invokeCbs()
    }

    fun getMPS():ModelPageState{
        return _modelPageState
    }
}

interface RestInterface<T,U> {
    suspend fun fetchAll()
    suspend fun create(entity:U)
    suspend fun update(entity:U)
    suspend fun delete(req: DeleteREQ)
}

abstract class DataStore<T : DTO, U: REQ>(val restPath: String) : Observed(), RestInterface<T,U> {
    private val items: MutableMap<Int, T> = mutableMapOf()
    private var _chosenId:Int? = null

    fun setChosenId(id:Int?){
        _chosenId = id
    }

    fun getChosenItem():T?{
        return items[_chosenId]
    }

    fun findById(id: Int): T? = items[id]

    fun getAll(): List<T> = items.values.toList()

    fun doIt() {
        console.log(restPath)
    }

    fun remove(id:Int){
        items.remove(id)
        invokeCbs()
    }

    fun commit(enitiy: T) {
        items[enitiy.id] = enitiy
        invokeCbs()
    }

    fun commitAll(enitiyList: List<T>) {
        items.putAll(enitiyList.map { it.id to it }.toMap())
        invokeCbs()
    }
}

object UserService : DataStore<UserDTO, UserREQ>(UserDTO.getRestPath()) {
    suspend fun resetPassword(entity: PasswordResetREQ) {
        val dto = jsonClient.post<UserDTO>("${endpoint}api$KEY_RESET_PASSWORD") {
            contentType(ContentType.Application.Json)
            body = entity
        }
        console.log("dto", dto)
    }

    //endpoint + "/api" + restPath
    suspend fun login(entity: LoginREQ) : LoginDTO {
        val dto = jsonClient.post<LoginDTO>(endpoint + "/api" + LoginREQ.path) {
            contentType(ContentType.Application.Json)
            body = entity
        }
        return dto
    }
    override suspend fun fetchAll() {
        TODO("Not yet implemented")
    }

    override suspend fun create(entity: UserREQ) {
        TODO("Not yet implemented")
    }

    override suspend fun update(entity: UserREQ) {
        TODO("Not yet implemented")
    }

    override suspend fun delete(req: DeleteREQ) {
        TODO("Not yet implemented")
    }
}

object PhotoService : DataStore<PhotoDTO, PhotoREQ>(PhotoDTO.getRestPath()) {
    override suspend fun fetchAll() {
        val resp = jsonClient.get<List<PhotoDTO>>(endpoint + "/api" + restPath)
        commitAll(resp)
        if(!Store.getAppLoaded()){
            Store.setAppLoaded("PhotoService")
        }
    }

    override suspend fun update(req:PhotoREQ){
        val dto = jsonClient.put<PhotoDTO>(endpoint + "/api" + restPath) {
            contentType(ContentType.Application.Json)
            body = req
        }
        commit(dto)
        Store.setMPS(ModelPageState.DEFAULT)
    }

    override suspend fun create(entity: PhotoREQ) {
        val dto = jsonClient.post<PhotoDTO>(endpoint + "/api" + restPath) {
            contentType(ContentType.Application.Json)
            body = entity
        }
        commit(dto)
        Store.setMPS(ModelPageState.DEFAULT)
    }

    fun dtoToReq(dto:PhotoDTO): PhotoREQ {
        return PhotoREQ(
            id = dto.id,
            fileName = dto.fileName,
            header = dto.header,
            title = dto.title,
            description  = dto.description,
            base64Photo = null,
            base64Thumb = null,
            galleryId = dto.gallery.id,
            dateTaken = null,
            tags = dto.tags.map { it.detail }
        )
    }

    override suspend fun delete(req: DeleteREQ) {
        val dto = jsonClient.delete<PhotoDTO>(endpoint + "/api" + restPath) {
            contentType(ContentType.Application.Json)
            body = req
        }
        setChosenId(null)
        remove(req.id?: throw MissingMandatoryFieldException(fieldName = "id"))
        Store.setMPS(ModelPageState.DEFAULT)
    }
}
object GalleryService : DataStore<GalleryDTO, GalleryREQ>(GalleryDTO.getRestPath()) {
    override suspend fun fetchAll() {
        val resp = jsonClient.get<List<GalleryDTO>>(endpoint + "/api" + restPath)
        commitAll(resp)
        if(!Store.getAppLoaded()){
            Store.setAppLoaded("GalleryService")
        }
    }

    override suspend fun create(entity: GalleryREQ) {
        val dto = jsonClient.post<GalleryDTO>(endpoint + "/api" + restPath) {
            contentType(ContentType.Application.Json)
            body = entity
        }
        commit(dto)
        Store.setMPS(ModelPageState.DEFAULT)
    }

    override suspend fun update(entity: GalleryREQ) {
        Store.setMPS(ModelPageState.DEFAULT)
        TODO("Not yet implemented")
    }

    override suspend fun delete(req: DeleteREQ) {
        TODO("Not yet implemented")
    }
}
object TagService : DataStore<TagDTO, TagREQ>(TagDTO.getRestPath()) {
    override suspend fun fetchAll() {
        console.log("window.location.origin", window.location.origin)
        console.log("endpoint", endpoint)
        val resp = jsonClient.get<List<TagDTO>>(endpoint + "/api" + restPath)
        commitAll(resp)
        if(!Store.getAppLoaded()){
            Store.setAppLoaded("TagService")
        }
    }

    suspend fun addTagToAllPhotos(entity: TagREQ) {
        val dto = jsonClient.post<TagDTO>(endpoint + "/api" + TagService.restPath) {
            contentType(ContentType.Application.Json)
            body = entity
        }
        TagService.commit(dto)
        PhotoService.fetchAll()
        Store.setMPS(ModelPageState.DEFAULT)
    }

    fun getTagByName(_name:String):TagDTO?{
        return getAll().firstOrNull{it.name == _name}
    }

    override suspend fun create(entity: TagREQ) {
        val dto = jsonClient.post<TagDTO>(endpoint + "/api" + restPath) {
            contentType(ContentType.Application.Json)
            body = entity
        }
        commit(dto)
        Store.setMPS(ModelPageState.DEFAULT)
    }

    override suspend fun update(entity: TagREQ) {
        Store.setMPS(ModelPageState.DEFAULT)
        TODO("Not yet implemented")
    }

    override suspend fun delete(req: DeleteREQ) {
        val dto = jsonClient.delete<TagDTO>(endpoint + "/api" + restPath) {
            contentType(ContentType.Application.Json)
            body = req
        }
        setChosenId(null)
        remove(req.id ?: throw MissingMandatoryFieldException(fieldName = "id"))
        PhotoService.fetchAll()
        Store.setMPS(ModelPageState.DEFAULT)
    }
}

object QuoteRequestService : DataStore<QuoteRequestDTO, TagREQ>(QuoteRequestDTO.getRestPath()) {
    override suspend fun fetchAll() {
        console.log("window.location.origin", window.location.origin)
        console.log("endpoint", endpoint)
        val resp = jsonClient.get<List<QuoteRequestDTO>>(endpoint + "/api" + restPath)
        commitAll(resp)
        if(!Store.getAppLoaded()){
            Store.setAppLoaded("QuoteRequestService")
        }
    }

    suspend fun runContactReport(){
        val resp = jsonClient.get<String>(endpoint + "/api" + KEY_RUN_QR_REPORT)
        console.log(resp)
    }

    override suspend fun create(entity: TagREQ) {
        TODO("Not yet implemented in QuoteRequestService")
    }

    override suspend fun update(entity: TagREQ) {
        TODO("Not yet implemented in QuoteRequestService")
    }

    override suspend fun delete(req: DeleteREQ) {
        TODO("Not yet implemented in QuoteRequestService")
    }
}