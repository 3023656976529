package net.ideablender.apofnj.page.tags

import net.ideablender.apofnj.common.*
import net.ideablender.apofnj.page.photo.displayPhotoTagHolder
import net.ideablender.apofnj.pojo.ModelPageState
import net.ideablender.apofnj.store.TagService
import react.RBuilder
import react.RComponent
import react.RProps
import react.RState

interface PhotoDetailState : RState {
}

interface TagDetailProps : RProps {
    var changePageState: (pageState:ModelPageState, id:Int) -> Unit
}

class TagDetail : RComponent<TagDetailProps, PhotoDetailState>() {
    override fun PhotoDetailState.init() {
    }

    override fun RBuilder.render() {
        val dto = TagService.getChosenItem()
        if(dto != null){
            card(cardWrapperCss = "PhotoDetail") {
                iRow{
                    iDetailLabel(value = "Title")
                    iDetailValue(value = dto.name)
                }
                iRow{
                    iDetailLabel(value = "Header")
                    iDetailValue(value = dto.photos.count().toString())
                }
                iRow{
                    iDetailLabel(value = "Photos")

                    dto.photos.forEach {
                        displayPhotoTagHolder(it)
                    }
                }
                dtoSummarySection(dto)

/*                hr{}
                iRow {
                       iCol("buttonRow"){
                        button(classes = "btn btn-warning", type = ButtonType.button){
                            attrs.onClickFunction = {
                                Store.setMPS(ModelPageState.EDIT)
                                PhotoService.setChosenId(dto.id)
                                it.stopPropagation()
                                it.preventDefault()
                            }
                            span("fas fa-edit") {}
                            +"Update"
                        }
                        button(classes = "btn btn-danger", type = ButtonType.button){
                            span("fas fa-trash") {}
                            +"Delete"
                        }
                    }
                }*/
            }
        }
    }
}