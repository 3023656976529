package net.ideablender.apofnj.page.photo

import kotlinx.browser.window
import kotlinx.html.js.onClickFunction
import react.RBuilder
import react.RComponent
import react.RProps
import react.RState
import react.dom.div
import react.dom.img
import react.dom.span

interface FullSizePhotoWrapperState : RState {
}

interface FullSizePhotoWrapperProps : RProps {
    var photoUrl:String
}

class FullSizePhotoWrapper : RComponent<FullSizePhotoWrapperProps, FullSizePhotoWrapperState>() {
    override fun FullSizePhotoWrapperState.init() {
    }

    override fun RBuilder.render() {
        div("FullSizePhotoWrapper") {
            div("relativeWrapper"){
                img(src = props.photoUrl, classes = "responsive") {

                }
                span("openFull fa fa-window-maximize"){
                    attrs.onClickFunction = {
                        window.open(url = props.photoUrl, target = "_blank")
                    }
                }
            }
        }
    }
}