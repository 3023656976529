package net.ideablender.apofnj.req
import kotlinx.serialization.Serializable

expect interface KCredential

@Serializable
data class LoginREQ(val name: String, val password: String): KCredential{
    companion object{
        val path = "/login"
    }
}
