package net.ideablender.apofnj.dto


import kotlinx.datetime.LocalDateTime
import kotlinx.serialization.Serializable

@Serializable
data class PropsDTO(
    val photoDir:String,
    val ingestionDir:String,
    val systemId:String,
    val dbName:String,
    val dbUser:String,
    val dbPort:Int,
    val dbMaxPoolSize:Int,
    val dbIsAutoCommit:Boolean,
    val dbInitMode:String,
    val dbTransactionIsolation:String,
    val siteLocatorFilePath:String,
    val mapBoxApiKey:String,
    val dbDriverClass:String,
    val envMode:String,
    val appVersion:String,
    val serverAddy:String,
    val mailSmtpHost:String,
    val mailSmtpPort:String,
    val mailSmtpAuth:String,
    val mailSmtpStartTtlsEnable:String,
    val mailSmtpUserName:String,
    val mailSmtpPassword:String,
    val mailAddyReplyTo:String,
    val mailAddyBcc:String,
    val jwtIssuer:String,
    val jwtRealm:String,
    val jwtValidity:Int,
    val jwtAudience:String,
    val msgOfTheDay:String,

)  {
    companion object{
        fun getRestPath(): String = "/buddyboy"
    }
}