package net.ideablender.apofnj.page

import crScrope
import kotlinx.browser.window
import kotlinx.coroutines.launch
import kotlinx.html.ButtonType
import kotlinx.html.id
import kotlinx.html.js.onClickFunction
import net.ideablender.apofnj.common.*
import net.ideablender.apofnj.req.PasswordResetREQ
import net.ideablender.apofnj.statics.STUB_PASSWORD_RESET
import net.ideablender.apofnj.store.UserService
import pullStubDataFromUrl
import react.*
import react.dom.*


interface PasswordResetState : RState {
    var uuid: String?
    var passw1: String?
    var passw2: String?
}


@JsExport
class PasswordReset() : RComponent<RProps, PasswordResetState>() {

    override fun componentDidMount() {
        setState {
            uuid = pullStubDataFromUrl(url = window.location.hash, stub = STUB_PASSWORD_RESET)
        }
    }

    fun isButtonDisabled(): Boolean {
        var disabled = false
        if (state.passw1.isNullOrBlank()) disabled = true
        if (state.passw2.isNullOrBlank()) disabled = true
        if (state.passw1 != state.passw2) disabled = true
        return disabled
    }

    private fun onInputChange(fd: FormData) {
        var _dirty = false
        when (fd.param) {
            "passw1" -> {
                setState {
                    passw1 = fd.value as? String
                }
            }

            "passw2" -> {
                setState {
                    passw2 = fd.value as? String
                }
            }
        }
    }


    override fun RBuilder.render() {
        div("pageNotFull") {
            attrs.id = "PasswordReset"
            h1 {
                +"Password Reset"
            }
            if (state.uuid == null) {
                p {
                    +"No uuid present in url, something went wrong"
                }
            } else {
                p {
                    +"Please fill out the form below to reset your password."
                }

                form {
                    iFormRow {
                        iFormLabel(value = "Password")
                        iInputWrapper {
                            iInput(
                                _value = state.passw1,
                                _name = "passw1",
                                onInputChange = ::onInputChange,
                                _placeholder = "New Password"
                            )
                        }
                    }

                    iFormRow {
                        iFormLabel(value = "Confirm")
                        iInputWrapper {
                            iInput(
                                _value = state.passw2,
                                _name = "passw2",
                                onInputChange = ::onInputChange,
                                _placeholder = "Confirm Password"
                            )
                            if ((!state.passw1.isNullOrBlank() && !state.passw2.isNullOrBlank()) && state.passw1 != state.passw2) {
                                p("errorText") { +"Your passwords must match." }
                            } else {
                                p {
                                    +" "
                                }
                            }

                        }
                    }


                    hr {}
                    button(classes = "btn btn-success pushRight", type = ButtonType.button) {
                        attrs.disabled = isButtonDisabled()
                        attrs.onClickFunction = {
                            val req = PasswordResetREQ(uuid = state.uuid, password = state.passw1)
                            crScrope.launch {
                                UserService.resetPassword(req)
                            }
                        }
                        span("fas fa-edit") {}
                        +"Reset Password"
                    }
                }
            }
        }
    }
}

