package net.ideablender.apofnj.page

import react.RBuilder
import react.RComponent
import react.RProps
import react.RState
import react.dom.h1


@JsExport
class PageHome() : RComponent<RProps, RState>() {
    override fun RBuilder.render() {
        h1{
            +"Home"
        }
    }
}