package net.ideablender.apofnj.page

import kotlinx.html.id
import net.ideablender.apofnj.dto.PropsDTO
import net.ideablender.apofnj.exception.MissingMandatoryFieldException
import net.ideablender.apofnj.store.Store
import react.RBuilder
import react.RComponent
import react.RProps
import react.RState
import react.dom.*


@JsExport
class Props : RComponent<RProps, RState>() {

    fun RBuilder.buildRow(label:String, data:String? = null){
        val display:String = data ?: ""
        tr {
            td{
                label {
                    +"$label :"
                }
            }
            td{
                +display
            }
        }
    }
    override fun RBuilder.render() {
        val p = Store.getProps()
        div {
            attrs.id = "propspage"
            h1 {
                +"Properties"
            }
        }

        table {
            tbody {
                buildRow("Props Message", p?.msgOfTheDay)
                buildRow("Photo Directory", p?.photoDir)
                buildRow("Ingestion Directory", p?.ingestionDir)
                buildRow("System Id", p?.systemId)
                buildRow("DB Name", p?.dbName)
                buildRow("DB User", p?.dbUser)
                buildRow("DB Port", p?.dbPort?.toInt().toString())
                buildRow("DB Max Pool Size", p?.dbMaxPoolSize?.toInt().toString())
                buildRow("DB Auto Commit", p?.dbIsAutoCommit?.toString())
                buildRow("DB Init Mode", p?.dbInitMode)
                buildRow("DB Transaction Isolation", p?.dbTransactionIsolation)
                buildRow("Site Locator Directory", p?.siteLocatorFilePath)
                buildRow("Mapbox Key", p?.mapBoxApiKey)
                buildRow("DB Driver Class", p?.dbDriverClass)
                buildRow("Env Mode", p?.envMode)
                buildRow("App Version", p?.appVersion)
                buildRow("Server Addy", p?.serverAddy)
                buildRow("Mail SMTP Host", p?.mailSmtpHost)
                buildRow("Mail SMTP Port", p?.mailSmtpPort)
                buildRow("Mail SMTP Auth", p?.mailSmtpAuth)
                buildRow("Mail SMTP TTLS", p?.mailSmtpStartTtlsEnable)
                buildRow("Mail SMTP User Name", p?.mailSmtpUserName)
                buildRow("Mail SMTP Password", p?.mailSmtpPassword)
                buildRow("Mail Reply To", p?.mailAddyReplyTo)
                buildRow("Mail BCC", p?.mailAddyBcc)
                buildRow("JWT Issuer", p?.jwtIssuer)
                buildRow("JWT Realm", p?.jwtRealm)
                buildRow("JWT Validity", p?.jwtValidity.toString())
                buildRow("JWT Audience", p?.jwtAudience)
            }
        }
    }
}