package net.ideablender.apofnj.pojos

import net.ideablender.apofnj.page.Login
import net.ideablender.apofnj.page.PageHome
import net.ideablender.apofnj.page.PasswordReset
import net.ideablender.apofnj.page.Props
import net.ideablender.apofnj.page.gallery.PageGallery
import net.ideablender.apofnj.page.photo.PagePhoto
import net.ideablender.apofnj.page.quoterequest.PageQuoteRequests
import net.ideablender.apofnj.page.tags.PageTags
import react.Component
import react.RProps
import kotlin.reflect.KClass

enum class NavLinkCtx(val linkText:String, val url:String, val secured:Boolean, val page: KClass<out Component<RProps, *>>){
    HOME(linkText = "Home", url = "/home", false, PageHome::class),
    PASSWORD_RESET(linkText = "Reset Password", url = "/passwordReset", false, PasswordReset::class),
    LOGIN(linkText = "Login", url = "/login", false, Login::class),
    PHOTOS(linkText = "Photos", url = "/photos", true, PagePhoto::class),
    PROPS(linkText = "Props", url = "/props", true, Props::class),
    GALLERIES(linkText = "Galleries", url = "/galleries", true, PageGallery::class),
    QUOTEREQUEST(linkText = "Quote Requests", url = "/quoteRequests", true, PageQuoteRequests::class),
    TAGS(linkText = "TAGS", url = "/tags", true, PageTags::class)
}