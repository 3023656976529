package net.ideablender.apofnj

import kotlinx.html.ButtonType
import kotlinx.html.js.onClickFunction
import net.ideablender.apofnj.pojo.ModelPageState
import net.ideablender.apofnj.pojo.pullAppVersion
import net.ideablender.apofnj.pojos.NavLinkCtx
import net.ideablender.apofnj.store.PhotoService
import net.ideablender.apofnj.store.Store
import react.*
import react.dom.*
import react.router.dom.*


interface HomeState : RState {
    var appLoaded: Boolean
}

@JsExport
class Home() : RComponent<RProps, HomeState>() {

    override fun componentDidMount() {
        PhotoService.registerCallback("PagePhoto", ::onStoreChange)
        Store.registerCallback("Home", ::onStoreChange)
    }

    private fun onStoreChange() {
        if (state.appLoaded == undefined || !state.appLoaded) {
            setState {
                appLoaded = Store.getAppLoaded()
            }
        }
    }


    fun RBuilder.navLi(navLinkCtx: NavLinkCtx, disabled: Boolean? = false) {
        li("nav-item") {
            attrs.onClickFunction = {
                Store.setMPS(ModelPageState.DEFAULT)
            }
            if (disabled == true) {
                a(href = navLinkCtx.url, classes = "nav-link disabled") { +navLinkCtx.linkText }
            } else {
                routeLink(to = navLinkCtx.url, className = "nav-link") { +navLinkCtx.linkText }
            }
        }
    }

    fun isLinkDisabled(): Boolean {
        return !state.appLoaded
    }

    override fun RBuilder.render() {
        div("container-fluid") {
            hashRouter {
                nav(classes = "navbar navbar-expand-lg navbar-light bg-light") {
                    a(classes = "navbar-brand", href = "#") {
                        +"Navbar"
                    }
                    button(classes = "navbar-toggler", type = ButtonType.button) {
                        span("navbar-toggler-icon") {}
                    }
                    div(classes = "collapse navbar-collapse") {
                        ul("navbar-nav mr-auto") {
                            val disabled = isLinkDisabled()
                            navLi(NavLinkCtx.HOME, disabled)
                            navLi(NavLinkCtx.GALLERIES, disabled)
                            navLi(NavLinkCtx.PHOTOS, disabled)
                            navLi(NavLinkCtx.QUOTEREQUEST, disabled)
                            navLi(NavLinkCtx.PROPS, disabled)
                        }
                    }
                }
                div("row no-gutters") {
                    div("col-12") {
                        if (state.appLoaded) {
                            switch {
                                route("/", true) {
                                    redirect(to = NavLinkCtx.HOME.url)
                                }
                            }
                            NavLinkCtx.values().forEach { navLink ->
                                var page = navLink.page
                                route(navLink.url, true) {
                                    if (navLink.secured && !Store.isUserLoggedIn()) {
                                        child(NavLinkCtx.LOGIN.page) {}
                                    }else{
                                        child(page) {}
                                    }

                                }
                            }
                        } else {
                            p {
                                +"Loading"
                            }
                        }
                    }
                }
                footer {
                    val appVersion = pullAppVersion()
                    div("row") {
                        div("col-12 content") {
                            span("appVersion") {
                                +"Version: ${appVersion.versionMajor}.${appVersion.versionMinor}.${appVersion.versionBuild}"
                            }
                        }
                    }
                }
            }
        }
    }
}
