package net.ideablender.apofnj.dto

import kotlinx.datetime.LocalDateTime
import kotlinx.serialization.Serializable


@Serializable
data class QuoteRequestDTO(
    override val id: Int,
    @Serializable(with = LocalDateTimeSerializer::class)
    override val dateCreated: LocalDateTime,
    @Serializable(with = LocalDateTimeSerializer::class)
    override val dateUpdated: LocalDateTime,
    var companyName:String? = null,
    var siteName:String? = null,
    var siteDescription:String? = null,
    var uuid:String,
    var siteStreet:String,
    var siteCity:String,
    var siteState:String,
    var siteCoords:MutableList<CoordinatesDTO> = mutableListOf(),
    var contactEmail:String,
    var contactPhone:String? = null,
    var contactNameFirst:String,
    var contactNameLast:String,
    var coordinates: List<DTOMin> = listOf()

) : DTO {
    companion object{
        fun getRestPath(): String = "/quoteRequest"
    }
}