package net.ideablender.apofnj.page.tags

import buildHeader
import kotlinx.html.ButtonType
import kotlinx.html.js.onClickFunction
import net.ideablender.apofnj.dto.TagDTO
import net.ideablender.apofnj.pojo.ModelPageState
import net.ideablender.apofnj.store.Store
import net.ideablender.apofnj.store.TagService
import react.*
import react.dom.button
import react.dom.h1
import react.dom.span


interface PageTagsState : RState {
    var tags:List<TagDTO>?
    var modelPageState: ModelPageState
}

@JsExport
class PageTags() : RComponent<RProps, PageTagsState>() {
    override fun PageTagsState.init() {
        tags = TagService.getAll()
        modelPageState = Store.getMPS()
    }
    override fun componentDidMount() {
        TagService.registerCallback("PageTags", ::onStoreChange)
        Store.registerCallback("PageTags", ::onStoreChange)
    }

    override fun componentWillUnmount() {
        TagService.unRegisterCallback("PageTags")
        Store.unRegisterCallback("PageTags")
    }

    private fun onStoreChange(){

        setState {
            tags = TagService.getAll()
            modelPageState = Store.getMPS()
        }
    }

    override fun RBuilder.render() {
        h1{
            +buildHeader("Tags", Store.getMPS())
            if(Store.getMPS() != ModelPageState.CREATE){
                button(classes = "btn btn-warning", type = ButtonType.button){
                    attrs.onClickFunction = {
                        TagService.setChosenId(null)
                        Store.setMPS(ModelPageState.CREATE)
                    }
                    span("fa fa-plus-square") {}
                    +"Add Tag To All Photos"
                }
            }
        }
        when(state.modelPageState){
            ModelPageState.DEFAULT -> {
                if(state.tags != null){
                    child(TagTable::class){
                        attrs.items = state.tags
                    }
                }
            }
            ModelPageState.EDIT, ModelPageState.CREATE -> {
                child(TagForm::class){}
            }
            ModelPageState.VIEW -> {
                child(TagDetail::class){}
            }
        }
    }
}