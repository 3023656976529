package net.ideablender.apofnj.page

import crScrope
import kotlinx.coroutines.async
import kotlinx.html.ButtonType
import kotlinx.html.id
import kotlinx.html.js.onClickFunction
import net.ideablender.apofnj.common.*
import net.ideablender.apofnj.dto.LoginDTO
import net.ideablender.apofnj.exception.MissingMandatoryFieldException
import net.ideablender.apofnj.req.LoginREQ
import net.ideablender.apofnj.store.Store
import net.ideablender.apofnj.store.UserService
import react.*
import react.dom.*


interface LoginState : RState {
    var eml: String?
    var passw: String?
    var response : LoginDTO?
}

@JsExport
class Login() : RComponent<RProps, LoginState>() {

    private fun onInputChange(fd: FormData) {
        var _dirty = false
        when (fd.param) {
            "eml" -> {
                setState {
                    eml = fd.value as? String
                }
            }

            "passw" -> {
                setState {
                    passw = fd.value as? String
                }
            }
        }
    }

    fun isButtonDisabled(): Boolean {
        var disabled = false
        if (state.eml.isNullOrBlank()) disabled = true
        if (state.passw.isNullOrBlank()) disabled = true
        return disabled
    }

    override fun RBuilder.render() {
        div{
            attrs.id = "Login"
            h1{
                +"Login"
            }

            div("loginWrapper"){
                if(state.response ==  null){
                    card(headerTxt = "Login"){

                        iFormRow {
                            iInputWrapper {
                                iInput(
                                    _value = state.eml,
                                    _name = "eml",
                                    onInputChange = ::onInputChange,
                                    _placeholder = "Email"
                                )
                            }
                        }
                        iFormRow {
                            iInputWrapper {
                                iInput(
                                    _value = state.passw,
                                    _name = "passw",
                                    onInputChange = ::onInputChange,
                                    _placeholder = "Password"
                                )
                            }
                        }

                        hr{}
                        button(classes = "btn btn-success pushRight", type = ButtonType.button) {
                            attrs.disabled = isButtonDisabled()
                            attrs.onClickFunction = {
                                val req = LoginREQ(name = state.eml ?: throw MissingMandatoryFieldException("name"),
                                    password = state.passw ?: throw MissingMandatoryFieldException("password"))
                                crScrope.async {
                                    val foo =  UserService.login(req)
                                    setState {
                                        response = foo
                                    }
                                }
                            }
                            span("fas fa-edit") {}
                            +"Login"
                        }
                    }
                }else{
                    if(state.response?.success == true){
                        p{
                            +"you are logged in"
                        }
                        Store.setToken(state.response?.token ?: throw MissingMandatoryFieldException("token"))
                    }else{
                        p{
                            +"login failed"
                        }
                    }
                }
            }
        }
    }
}