package net.ideablender.apofnj.common

import kotlinx.html.InputType
import kotlinx.html.js.onChangeFunction
import net.ideablender.apofnj.dto.DTO
import net.ideablender.apofnj.dto.DTOMin
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLSelectElement
import org.w3c.dom.events.Event
import react.RBuilder
import react.dom.*


enum class FormDataType {
    STRING,
    DATE,
    INT
}

data class FormData(val label: String, val value: dynamic, val param: String, val formDataType: FormDataType = FormDataType.STRING) {
    companion object {
        fun eventToFormData(event: Event): FormData {
            val target = event.target as HTMLInputElement
            return FormData(param = target.name, value = target.value, label = target.value)
        }
    }

    fun toDtoMin(): DTOMin {
        return when (formDataType) {
            FormDataType.STRING -> {
                val str = this.value as String
                DTOMin(id = str.toInt(), detail = this.label)
            }
            FormDataType.DATE -> {
                DTOMin(id = 0, detail = this.label)
            }
            FormDataType.INT -> {
                DTOMin(id = this.value, detail = this.label)
            }
        }
    }
}

fun RBuilder.card(headerTxt: String? = null, cardBodyTitle: String? = null, cardWrapperCss:String? = null, addBottomMargin: Boolean? = true, cardBodyCss: String? = null, cardTitleCss: String? = null, body: RBuilder.() -> Unit) {
    div("card $cardWrapperCss ${if (addBottomMargin == true) "addBottomMarginDouble" else ""}") {
        if (headerTxt != null) {
            div("card-header") {
                +headerTxt
            }
        }
        div(classes = "card-body $cardBodyCss") {
            if (cardBodyTitle != null) {
                h5("card-title $cardTitleCss") {
                    +cardBodyTitle
                }
            }
            body()
        }
    }
}

fun RBuilder.iRow(classes:String? = null, body: RBuilder.() -> Unit){
    div("row $classes"){
        body()
    }
}

fun RBuilder.iFormRow(classes:String? = null, body: RBuilder.() -> Unit){
    div("form-group row $classes"){
        body()
    }
}
fun RBuilder.iDetailLabel(classes:String? = null, value: String){
    label("iDetailLabel col-2 $classes"){
        +"${value}:"
    }
}
fun RBuilder.iDetailValue(classes:String? = null, value: String){
    span("iDetailValue"){
        +value
    }
}

fun RBuilder.iFormLabel(classes:String? = null, value: String){
    label("col-sm-2 col-form-label $classes"){
        +"${value}:"
    }
}
fun RBuilder.iInputWrapper(classes:String? = null, body: RBuilder.() -> Unit){
    div("col-sm-10 $classes"){
        body()
    }
}



fun RBuilder.dtoSummarySection(dto:DTO){
    hr{}
    iRow{
        iDetailLabel(value = "Id")
        iDetailValue(value = dto.id.toString())
    }
    iRow{
        iDetailLabel(value = "Date Created")
        iDetailValue(value = dto.dateCreated.toString())
    }
    iRow{
        iDetailLabel(value = "Date Updated")
        iDetailValue(value = dto.dateUpdated.toString())
    }
}

fun RBuilder.iCol(classes:String? = null, body: RBuilder.() -> Unit){
    div("col-sm-12 $classes"){
        body()
    }
}

fun RBuilder.iInput(classes:String? = null, _value: String?,_placeholder :String? = null,  _name: String, _readOnly:Boolean = false, onInputChange: (FormData) -> Unit){
    input(classes = "form-control $classes", type = InputType.text, name = _name) {
        attrs.value = _value ?: ""
        attrs.readonly = _readOnly
        if(_placeholder != null){
            attrs.placeholder = _placeholder
        }
        attrs.onChangeFunction = {
            onInputChange(FormData.eventToFormData(it))
        }
    }
}

fun RBuilder.iSelect(classes:String? = null, _value: String?, _name: String, _readOnly:Boolean = false, optionValueList : List<OptionValue>, onInputChange: (FormData) -> Unit){
    select(classes = "form-control selectDD $classes") {
        attrs.value = _value ?: ""
        attrs.onChangeFunction = {
            val target = it.target as HTMLSelectElement
            onInputChange(FormData(label = target.value, value = target.value, param = _name))
        }
        optionValueList.forEach { optionValue ->
            option {
                attrs.value = optionValue.value
                +optionValue.display
            }
        }
    }
}


data class OptionValue(val value: String, val display: String)

fun RBuilder.selectDD(value: String, onChange: (FormData) -> Unit, fieldName: String, optionList: List<OptionValue>) {
    select(classes = "form-control selectDD") {
        attrs.value = value
        attrs.onChangeFunction = { event ->
            val target = event.target as HTMLSelectElement
            onChange(FormData(label = target.value, value = target.value, param = fieldName))
        }
        optionList.forEach {
            option {
                attrs.value = it.value
                +it.display
            }
        }
    }
}