package net.ideablender.apofnj.page.quoterequest

import crScrope
import kotlinx.coroutines.launch
import kotlinx.html.ButtonType
import kotlinx.html.js.onClickFunction
import net.ideablender.apofnj.common.SortDirection
import net.ideablender.apofnj.common.TableSort
import net.ideablender.apofnj.dto.QuoteRequestDTO
import net.ideablender.apofnj.dto.TagDTO
import net.ideablender.apofnj.page.photo.TableColumn
import net.ideablender.apofnj.store.QuoteRequestService
import react.*
import react.dom.*
import kotlin.js.Date

interface QuoteRequestTableState : RState {
   /* var chosenTag:String?*/
    var sortDirection:SortDirection
    var tableColumn: QuoteRequestTableColumn
    var items :List<QuoteRequestDTO>?
    var reportBtnClicked:Boolean
}

interface QuoteRequestTableProps : RProps {
    var items:List<QuoteRequestDTO>?
}
enum class QuoteRequestTableColumn(override val description:String) : TableColumn {
    EMAIL("Email"),
    SITE_CITY("City")
}

class QuoteRequestTable : RComponent<QuoteRequestTableProps, QuoteRequestTableState>() {
    override fun QuoteRequestTableState.init() {
        sortDirection = SortDirection.UP
        tableColumn = QuoteRequestTableColumn.EMAIL
        reportBtnClicked = false
    }

    override fun componentDidMount() {
        QuoteRequestService.registerCallback("QuoteRequestTable", ::onStoreChange)
        setState {
            items = props.items
        }
    }

    override fun componentWillUnmount() {
        QuoteRequestService.unRegisterCallback("QuoteRequestTable")
    }

    private fun onStoreChange(){
        setState {
            items = QuoteRequestService.getAll().sortedBy { it.contactEmail }
        }
    }

    private fun onTableSortClick(_column:TableColumn, _direction: SortDirection){
        console.log("""
            column : ${_column}
            direction : ${_direction}
        """.trimIndent())
        setState {
            sortDirection = _direction
            tableColumn = _column as QuoteRequestTableColumn
            items = when(_direction){
                SortDirection.UP -> {
                    when(state.tableColumn){
                        //QuoteRequestTableColumn.EMAIL -> state.items!!.sortedBy { it.contactEmail.toLowerCase() }
                        QuoteRequestTableColumn.EMAIL -> state.items!!.sortedWith(compareBy<QuoteRequestDTO> { it.contactEmail }.thenBy { it.siteCity })
                        QuoteRequestTableColumn.SITE_CITY -> state.items!!.sortedWith(compareBy<QuoteRequestDTO> { it.siteCity }.thenBy { it.contactEmail })
                    }

                }
                SortDirection.DOWN -> {
                    when(state.tableColumn){
                        QuoteRequestTableColumn.EMAIL -> state.items!!.sortedWith(compareByDescending<QuoteRequestDTO> { it.contactEmail }.thenBy { it.siteCity })
                        QuoteRequestTableColumn.SITE_CITY -> state.items!!.sortedWith(compareByDescending<QuoteRequestDTO> { it.siteCity }.thenBy { it.contactEmail })
                    }
                }
            }
        }
    }

    private fun getConfirmMessage(dto: TagDTO) = "Are you sure you want to delete tag ${dto.name}?"

    override fun RBuilder.render() {
        table(classes = "ModelTable table table-striped table-bordered table-sm table-hover flex-table") {
            thead("thead-dark") {
                tr {
                    th {
                        child(TableSort::class){
                            attrs.tableColumn = QuoteRequestTableColumn.EMAIL
                            attrs.onArrowClick = :: onTableSortClick
                            attrs.currColumn = state.tableColumn
                            attrs.currDirection = state.sortDirection
                        }
                    }
                    th{
                        child(TableSort::class){
                            attrs.tableColumn = QuoteRequestTableColumn.SITE_CITY
                            attrs.onArrowClick = :: onTableSortClick
                            attrs.currColumn = state.tableColumn
                            attrs.currDirection = state.sortDirection
                        }
                    }
                    th{
                        +"State"
                    }
                    th{
                        +"Street"
                    }
                    th{
                        +"Date"
                    }
                }
            }
            tbody {
                if(state.items != null){
                    state.items!!.forEach { item ->
                       /* val selected = state.chosenTag == item.name*/
                        /*val rowClass = if(selected) "rowSelected" else ""*/
                        tr(classes = ""){
/*                            attrs.onClickFunction = {
                                onRowClick(item, selected)
                            }*/
                            td("noWrap") {
                                +item.contactEmail
                            }
                            td{
                                +item.siteCity
                            }
                            td{
                                +item.siteState
                            }
                            td{
                                +item.siteStreet
                            }
                            td{
                                +item.dateCreated.toString()
                            }
                    /*        td("noWrap"){
                                span("fas fa-image") {
                                    attrs.onClickFunction = {
                                        Store.setMPS(ModelPageState.VIEW)
                                        TagService.setChosenId(item.id)
                                        it.stopPropagation()
                                        it.preventDefault()
                                    }
                                }
                                span("fas fa-trash") {
                                    attrs.onClickFunction = {
                                        it.preventDefault()
                                        if(window.confirm(getConfirmMessage(item))){
                                            console.log("delete")
                                            setState {
                                                chosenTag = null
                                            }
                                            crScrope.launch {
                                                TagService.delete(DeleteREQ(item.id))
                                            }
                                        }else{
                                            console.log("DO NOT DELTE")
                                        }
                                    }
                                }
                            }*/
                        }
                    }
                }
            }
            tfoot {
                tr{
                    td{
                        attrs.colSpan = "5"
                        button(classes = "btn btn-success", type = ButtonType.button){
                            attrs.disabled = state.reportBtnClicked
                            attrs.onClickFunction = {
                                setState {
                                    reportBtnClicked = true
                                }
                                crScrope.launch{
                                    QuoteRequestService.runContactReport()
                                }
                            }
                            span("fa fa-plus-square") {}
                            +"Generate Report"
                        }
                    }
                }
            }
        }
    }
}

fun Long.toDateFormatShort():String{
    val d = Date(this)
    val month = d.getMonth() +1
    return "${month}/${d.getDate()}/${d.getFullYear()}"
}