package net.ideablender.apofnj.common

import kotlinx.html.js.onClickFunction
import net.ideablender.apofnj.page.photo.TableColumn
import react.RBuilder
import react.RComponent
import react.RProps
import react.RState
import react.dom.div
import react.dom.span


enum class SortDirection{
    UP,
    DOWN
}

interface TableSortProps : RProps {
    var currDirection:SortDirection
    var currColumn:TableColumn
    var tableColumn: TableColumn
    var onArrowClick:  (TableColumn,SortDirection) -> Unit
}

class TableSort : RComponent<TableSortProps, RState>() {

    private fun amIActive(_direction:SortDirection) = props.currColumn == props.tableColumn && props.currDirection == _direction

    override fun RBuilder.render() {
        div("TableSort") {
            span("headerValue"){
                +props.tableColumn.description
            }
            div("arrows"){
                span("sortUp fas fa-angle-up fa-xs ${if (amIActive(SortDirection.UP)) "active" else ""}"){
                    if(!amIActive(SortDirection.UP)){
                        attrs.onClickFunction = {
                            props.onArrowClick(props.tableColumn, SortDirection.UP)
                        }
                    }

                }
                span("sortUp fas fa-angle-down fa-xs ${if (amIActive(SortDirection.DOWN)) "active" else ""}"){
                    if(!amIActive(SortDirection.DOWN)){
                        attrs.onClickFunction = {
                            props.onArrowClick(props.tableColumn, SortDirection.DOWN)
                        }
                    }
                }
            }
        }
    }
}