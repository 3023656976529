package net.ideablender.apofnj.page.photo

import buildHeader
import kotlinx.html.ButtonType
import kotlinx.html.js.onClickFunction
import net.ideablender.apofnj.dto.PhotoDTO
import net.ideablender.apofnj.pojo.ModelPageState
import net.ideablender.apofnj.store.PhotoService
import net.ideablender.apofnj.store.Store
import react.*
import react.dom.button
import react.dom.h1
import react.dom.span


interface PagePhotoState : RState {
    var photos:List<PhotoDTO>?
    var modelPageState:ModelPageState
}

@JsExport
class PagePhoto() : RComponent<RProps, PagePhotoState>() {
    override fun PagePhotoState.init() {
        photos = PhotoService.getAll()
        modelPageState = Store.getMPS()
    }
    override fun componentDidMount() {
        PhotoService.registerCallback("PagePhoto", ::onStoreChange)
        Store.registerCallback("PagePhoto", ::onStoreChange)
    }

    override fun componentWillUnmount() {
        PhotoService.unRegisterCallback("PagePhoto")
        Store.unRegisterCallback("PagePhoto")
    }
    private fun onStoreChange(){
        setState {
            photos = PhotoService.getAll()
            modelPageState = Store.getMPS()
        }
    }

    override fun RBuilder.render() {
        h1{
            +buildHeader("Photos", Store.getMPS())
            if(Store.getMPS() != ModelPageState.CREATE){
                button(classes = "btn btn-warning", type = ButtonType.button){
                    attrs.onClickFunction = {
                        PhotoService.setChosenId(null)
                        Store.setMPS(ModelPageState.CREATE)
                    }
                    span("fa fa-plus-square") {}
                    +"Create"
                }
            }
        }
        when(state.modelPageState){
            ModelPageState.DEFAULT -> {
                if(state.photos != null){
                    child(PhotoTable::class){
                        attrs.items = state.photos
                    }
                }
            }
            ModelPageState.EDIT, ModelPageState.CREATE -> {
                child(PhotoForm::class){
                    attrs.pageState = Store.getMPS()
                }
            }
            ModelPageState.VIEW -> {
                child(PhotoDetail::class){}
            }
        }
    }
}