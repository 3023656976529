package net.ideablender.apofnj.statics


import kotlinx.datetime.Clock
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import net.ideablender.apofnj.dto.*
import net.ideablender.apofnj.pojo.AccountStatus
import net.ideablender.apofnj.pojo.RoleType
import net.ideablender.apofnj.req.JobREQ
import net.ideablender.apofnj.req.UserREQ


const val VERSION_MAJOR = "000"
const val VERSION_MINOR = "001"
const val VERSION_BUILD="008"

const val FILE_NAME_PROPS = "apofnj.properties"
const val FILE_NAME_PROPS_TEST = "apofnj-test.properties"
const val FOLDER_DEPLOYMENT = "/conf"
const val ENV_VAR = "envMode"
 const val ZIP_BUFFER_SIZE = 4096
const val KEY_ENV_MODE = "env.mode"
const val KEY_PROPS_APP_MSG = "app.msg"
const val KEY_PROPS_PHOTO_DIR = "fs.photoDirectory"
const val KEY_PROPS_INGESTION_DIR = "fs.ingestionPath"
const val KEY_PROPS_DB_NAME = "db.name"
const val KEY_PROPS_DB_DRIVER_CLASS = "db.driverClass"
const val KEY_PROPS_DB_PORT = "db.port"
const val KEY_PROPS_DB_USER = "db.user"
const val KEY_PROPS_DB_PASSWORD = "db.password"
const val KEY_PROPS_DB_URL = "db.url"
const val KEY_PROPS_DB_INIT_MODE = "db.initMode"
const val KEY_PROPS_DB_MAX_POOL_SIZE = "db.maximumPoolSize"
const val KEY_PROPS_DB_IS_AUTO_COMMIT = "db.isAutoCommit"
const val KEY_PROPS_DB_TRANSACTION_ISOLATION = "db.transactionIsolation"
const val KEY_PROPS_SITELOCATOR_PATH = "fs.siteLocatorFilePath"
const val KEY_PROPS_API_KEY_MAPBOX = "api.key.mapbox"
const val KEY_JWT_SECRET = "jwt.secret"
const val KEY_JWT_VALIDITY = "jwt.validity.ms"
const val KEY_JWT_ISSUER = "jwt.issuer"
const val KEY_JWT_REALM = "jwt.realm"
const val KEY_JWT_AUDIENCE = "jwt.audience"

const val KEY_PROPS_MAIL_SMTP_HOST = "mail.smtp.host"
const val KEY_PROPS_MAIL_SMTP_PORT = "mail.smtp.port"
const val KEY_PROPS_MAIL_SMTP_AUTH = "mail.smtp.auth"
const val KEY_PROPS_MAIL_SMTP_STARTTTLS_ENABLE = "mail.smtp.starttls.enable"
const val KEY_PROPS_MAIL_SMTP_USERNAME = "mail.smtp.username"
const val KEY_PROPS_MAIL_SMTP_PASSWORD = "mail.smtp.password"
const val KEY_PROPS_MAIL_ADDY_REPLYTO = "mail.addy.replyTo"
const val KEY_PROPS_MAIL_ADDY_BCC = "mail.addy.bcc"


const val DELAY_INGESTION:Long = 9000

const val EMAIL_HEADER_SETUP = "Apofnj Account Setup"

const val TXT_EXCEPTION_WITHOUT_MSG = "The exception message was null"


const val CLAZZ_USER = "USER"

const val DB_COLUMN_DATE_CREATED = "date_created"
const val DB_COLUMN_DATE_UPDATED = "date_updated"

const val PATTERN_FORMAT_DATE_TIME = "MM/dd/yyyy h:m:s a"
const val EXTENSION_PHOTO = "jpg"
const val EXTENSION_ZIP = "zip"
const val TEMP_FOLDER_INGEST = "/tmp/ingestTemp"
const val SYSTEM_ID_DEV = "chriss"
const val MAC_TRASH_MACOSX = "__MACOSX"
const val MAC_TRASH_DSSTORE = ".DS_Store"
const val TXT_THUMB_NAME_MODIFIER = "-thumb"
const val NAV_KEY_HOME = "/home"

const val NEW_LINE = "\n"

val NAV_KEY_LOGIN = "/login"
val NAV_KEY_PHOTOS = PhotoDTO.getRestPath()
val NAV_KEY_TAGS = TagDTO.getRestPath()
val NAV_KEY_GALLERIES = GalleryDTO.getRestPath()
val NAV_KEY_QUOTE_REQUEST = QuoteRequestDTO.getRestPath()
const val REST_HANDLE_MAPBOX_KEY = "/mapboxApiKey"
const val KEY_IMAGES = "/images"
const val KEY_RUN_QR_REPORT = "/runQrReport"
const val KEY_SITE_LOCATOR_FILES = "/siteLocatorFiles"
const val KEY_RESET_PASSWORD = "/passwordReset"

const val URL_DEV = "http://localhost:8081/#/"
const val URL_STAGING = "https://apofnj.us/#/"
const val URL_PROD = "https://apofnj.com/"

const val STUB_PASSWORD_RESET = "passwdUUID"

val TIMEZONE_EASTERN : TimeZone = TimeZone.of("UTC+4")

val TL_ALLOWED = listOf(
    "q",
    "w",
    "e",
    "r",
    "t",
    "a",
    "s",
    "d",
    "f",
    "g",
    "z",
    "x",
    "c",
    "v",
    "b",
    "y",
    "u",
    "i",
    "o",
    "p",
    "h",
    "j",
    "k",
    "l",
    "n",
    "m",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "0",
    "-",
    "_",
)

val TL_ALLOWED_EMAIL = TL_ALLOWED.plus("@").plus(".")

val TL_ALLOWED_XML = TL_ALLOWED.plus(" ").plus(".").plus(",")

val LIST_STATES = listOf(
    "AK",
    "AL",
    "AR",
    "AS",
    "AZ",
    "CA",
    "CO",
    "CT",
    "DC",
    "DE",
    "FL",
    "GA",
    "GU",
    "HI",
    "IA",
    "ID",
    "IL",
    "IN",
    "KS",
    "KY",
    "LA",
    "MA",
    "MD",
    "ME",
    "MI",
    "MN",
    "MO",
    "MP",
    "MS",
    "MT",
    "NC",
    "ND",
    "NE",
    "NH",
    "NJ",
    "NM",
    "NV",
    "NY",
    "OH",
    "OK",
    "OR",
    "PA",
    "PR",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UM",
    "UT",
    "VA",
    "VI",
    "VT",
    "WA",
    "WI",
    "WV",
    "WY"
)

val TEST_JOB_REQ_1 = JobREQ(
    companyName = "Big Willy Enterprises",
    contactEmail = "joe@bwe.net",
    contactNameFirst = "Joe",
    contactNameLast = "Namath",
    siteCity = "Browns Summit",
    siteState = "NC",
    siteStreet = "2589 Mccullum Court",
    siteName = "The southern  command",
    siteDescription = "This is the southern command",
    siteCoords = mutableListOf(
        CoordinatesDTO(
            latitude = 36.225249260000936,
            longitude = -79.74356293349096,
            dateCreated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC),
            dateUpdated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC)
        ),
        CoordinatesDTO(
            latitude = 36.22527797081575,
            longitude = -79.74375232556085,
            dateCreated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC),
            dateUpdated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC)
        ),
        CoordinatesDTO(
            latitude = 36.22514159435372,
            longitude = -79.74382223538535,
            dateCreated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC),
            dateUpdated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC)
        ),
        CoordinatesDTO(
            latitude = 36.22500316687439,
            longitude = -79.74355912022784,
            dateCreated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC),
            dateUpdated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC)
        ),
        CoordinatesDTO(
            latitude = 36.225249260000936,
            longitude = -79.74356293349096,
            dateCreated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC),
            dateUpdated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC)
        ),
    ),
)

val TEST_JOB_REQ_2 = JobREQ(
    companyName = "Buddy Enterprises Inc",
    contactEmail = "buddy.j.legge@gmail.com",
    contactNameFirst = "Buddy",
    contactNameLast = "Legge",
    contactPhone = "13364821579",
    siteCity = "Staten Island",
    siteState = "NY",
    siteStreet = "357 Rose Ave",
    siteName = "The MotherShip",
    siteDescription = "This is the northern command.",
    siteCoords = mutableListOf(
        CoordinatesDTO(
            latitude = 40.56992499851456,
            longitude = -74.11043289446938,
            dateCreated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC),
            dateUpdated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC)
        ),
        CoordinatesDTO(
            latitude = 40.570953843041714,
            longitude = -74.11247456756159,
            dateCreated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC),
            dateUpdated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC)
        ),
        CoordinatesDTO(
            latitude = 40.570406479449815,
            longitude = -74.11298832190194,
            dateCreated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC),
            dateUpdated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC)
        ),
        CoordinatesDTO(
            latitude = 40.56935228510203,
            longitude = -74.1109533209441,
            dateCreated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC),
            dateUpdated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC)
        ),
        CoordinatesDTO(
            latitude = 40.56992499851456,
            longitude = -74.11043289446938,
            dateCreated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC),
            dateUpdated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC)
        ),
    ),
)

val TEST_JOB_REQ_3 = JobREQ(
    companyName = "PBE Enterprises Inc",
    contactEmail = "hugh@aol.com",
    contactNameFirst = "Hugh",
    contactNameLast = "Hefner",
    contactPhone = "5553334444",
    siteCity = "Los Angeles",
    siteState = "CA",
    siteStreet = "10236 Charing Cross Rd",
    siteName = "The MotherShip",
    siteDescription = "This is the northern command.",
    siteCoords = mutableListOf(
        CoordinatesDTO(
            latitude = 34.07688564593019,
            longitude = -118.42900157286263,
            dateCreated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC),
            dateUpdated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC)
        ),
        CoordinatesDTO(
            latitude = 34.07676387518979,
            longitude = -118.42952347663531,
            dateCreated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC),
            dateUpdated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC)
        ),
        CoordinatesDTO(
            latitude = 34.07720833754573,
            longitude = -118.42956758117951,
            dateCreated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC),
            dateUpdated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC)
        ),
        CoordinatesDTO(
            latitude = 34.07717180648152,
            longitude = -118.42975870087108,
            dateCreated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC),
            dateUpdated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC)
        ),
        CoordinatesDTO(
            latitude = 34.07671110781392,
            longitude = -118.42968519329713,
            dateCreated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC),
            dateUpdated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC)
        ),
        CoordinatesDTO(
            latitude = 34.0765406283772,
            longitude = -118.43034921171227,
            dateCreated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC),
            dateUpdated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC)
        ),
        CoordinatesDTO(
            latitude = 34.0763315876467,
            longitude = -118.43038106499404,
            dateCreated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC),
            dateUpdated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC)
        ),
        CoordinatesDTO(
            latitude = 34.076384355258234,
            longitude = -118.43057708519012,
            dateCreated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC),
            dateUpdated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC)
        ),
        CoordinatesDTO(
            latitude = 34.07621387516306,
            longitude = -118.43074615260939,
            dateCreated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC),
            dateUpdated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC)
        ),
        CoordinatesDTO(
            latitude = 34.07581811647753,
            longitude = -118.43029775641065,
            dateCreated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC),
            dateUpdated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC)
        ),
        CoordinatesDTO(
            latitude = 34.0763437647909,
            longitude = -118.42873449534558,
            dateCreated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC),
            dateUpdated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC)
        ),
        CoordinatesDTO(
            latitude = 34.07688564593019,
            longitude = -118.42900157286263,
            dateCreated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC),
            dateUpdated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC)
        ),
    ),
)

val TEST_JOB_REQ_4 = JobREQ(
    companyName = "Ny Yankees, Ltd",
    contactEmail = "george@yankees.com",
    contactNameFirst = "George",
    contactNameLast = "Steinbrenner",
    contactPhone = "4445556666",
    siteCity = "The Bronx",
    siteState = "NY",
    siteStreet = "1 E 161 St",
    siteName = "Yankee Stadium",
    siteDescription = "The house that Jeter built.",
    siteCoords = mutableListOf(
        CoordinatesDTO(
            latitude = 40.830653851029126,
            longitude = -73.92447013489306,
            dateCreated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC),
            dateUpdated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC)
        ),
        CoordinatesDTO(
            latitude = 40.831336997351514,
            longitude = -73.9264512451083,
            dateCreated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC),
            dateUpdated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC)
        ),
        CoordinatesDTO(
            latitude = 40.829203984795356,
            longitude = -73.9286337140673,
            dateCreated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC),
            dateUpdated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC)
        ),
        CoordinatesDTO(
            latitude = 40.828270155860345,
            longitude = -73.92578221444575,
            dateCreated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC),
            dateUpdated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC)
        ),
        CoordinatesDTO(
            latitude = 40.830653851029126,
            longitude = -73.92447013489306,
            dateCreated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC),
            dateUpdated = Clock.System.now().toLocalDateTime(kotlinx.datetime.TimeZone.UTC)
        ),
    ),
)
const val MSG_SERVER_CALL_WORKED = "MSG_SERVER_CALL_WORKED"

val USER_REQ_ROOT = UserREQ(
    roleType = RoleType.ROOT.name,
    nameFirst = "chris",
    nameLast = "Legge",
    password = "foobar",
    email = "christopher.j.legge@gmail.com"
)

val USER_REQ_JM = UserREQ(
    roleType = RoleType.ADMIN.name,
    nameFirst = "John",
    nameLast = "Majoris",
    password = "foobar",
    email = "john@aponf.us"
)

val USER_REQ_CC = UserREQ(
    roleType = RoleType.USER.name,
    nameFirst = "Cliff",
    nameLast = "Compton",
    password = "foobar",
    email = "cc@aponf.us"
)




