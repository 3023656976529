package net.ideablender.apofnj.dto

import kotlinx.datetime.*
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object LocalDateTimeSerializer : KSerializer<LocalDateTime> {
    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("LocalDateTime", PrimitiveKind.LONG)
    override fun deserialize(decoder: Decoder): LocalDateTime {
        val epoch = decoder.decodeLong()
        val instant = Instant.fromEpochSeconds(epoch)
        return instant.toLocalDateTime(TimeZone.UTC)

    }

    override fun serialize(encoder: Encoder, value: LocalDateTime) {
        val epoch:Long = value.toInstant(TimeZone.UTC).epochSeconds
        encoder.encodeLong(epoch)
    }
}