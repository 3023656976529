package net.ideablender.apofnj.page.gallery

import kotlinx.html.js.onClickFunction
import net.ideablender.apofnj.common.SortDirection
import net.ideablender.apofnj.common.TableSort
import net.ideablender.apofnj.dto.GalleryDTO
import net.ideablender.apofnj.dto.PhotoDTO
import net.ideablender.apofnj.page.photo.TableColumn
import net.ideablender.apofnj.store.GalleryService
import net.ideablender.apofnj.store.PhotoService
import react.*
import react.dom.*

interface GalleryTableState : RState {
    var chosenGallery:String?
    var sortDirection:SortDirection
    var tableColumn: GalleryTableColumn
    var gallerys :List<GalleryDTO>?
}

interface GalleryTableProps : RProps {
    var items:List<GalleryDTO>?
}
enum class GalleryTableColumn(override val description:String) : TableColumn {
    _NAME("Name"),
    DESCRIPTION("Description"),
}

class GalleryTable : RComponent<GalleryTableProps, GalleryTableState>() {
    override fun GalleryTableState.init() {
        sortDirection = SortDirection.DOWN
        tableColumn = GalleryTableColumn._NAME
    }

    override fun componentDidMount() {
        PhotoService.registerCallback("GalleryTable", ::onStoreChange)
        setState {
            gallerys = props.items
        }
    }

    override fun componentWillUnmount() {
        PhotoService.unRegisterCallback("GalleryTable")
    }

    private fun onStoreChange(){
        setState {
            gallerys = GalleryService.getAll()
        }
    }

    private fun onRowClick(item:GalleryDTO, selected: Boolean){
        if(selected){
            setState {
                chosenGallery = null
            }
        }else{
            setState {
                chosenGallery = item.name
            }
        }
    }

    private fun onTableSortClick(_column:TableColumn, _direction: SortDirection){
        setState {
            sortDirection = _direction
            tableColumn = _column as GalleryTableColumn
            gallerys = when(state.sortDirection){
                SortDirection.UP -> {
                    when(state.tableColumn){
/*                        GalleryTableColumn.EMAIL -> state.gallerys!!.sortedBy {
                        it.name.toLowerCase()
                    }
                        GalleryTableColumn.DESCRIPTION -> state.photos!!.sortedBy { it.title.toLowerCase() }
                        GalleryTableColumn.GALLERY -> state.photos!!.sortedBy { it.gallery.detail.toLowerCase() }*/
                        else -> TODO()
                    }

                }
                SortDirection.DOWN -> {
                    when(state.tableColumn){
/*                        GalleryTableColumn.EMAIL -> state.photos!!.sortedByDescending { it.fileName.toLowerCase() }
                        GalleryTableColumn.DESCRIPTION -> state.photos!!.sortedByDescending { it.title.toLowerCase() }
                        GalleryTableColumn.GALLERY -> state.photos!!.sortedByDescending { it.gallery.detail.toLowerCase() }*/
                        else -> TODO()
                    }
                }
            }
        }
    }

    private fun getConfirmMessage(dto: PhotoDTO) = "Are you sure you want to delete photo ${dto.title}?"

    override fun RBuilder.render() {
        table(classes = "ModelTable table table-striped table-bordered table-sm table-hover") {
            thead("thead-dark") {
                tr {
                    th {
                        child(TableSort::class){
                            attrs.tableColumn = GalleryTableColumn._NAME
                            attrs.onArrowClick = :: onTableSortClick
                            attrs.currColumn = state.tableColumn
                            attrs.currDirection = state.sortDirection
                        }
                    }
                    th {
                        child(TableSort::class){
                            attrs.tableColumn = GalleryTableColumn.DESCRIPTION
                            attrs.onArrowClick = :: onTableSortClick
                            attrs.currColumn = state.tableColumn
                            attrs.currDirection = state.sortDirection
                        }
                    }
                    th{}
                }
            }
            tbody {
                if(state.gallerys != null){
                    state.gallerys!!.forEach { item ->
                        val selected = state.chosenGallery == item.name
                        val rowClass = if(selected) "rowSelected" else ""
                        tr(classes = rowClass){
                            attrs.onClickFunction = {
                                onRowClick(item, selected)
                            }
                            td("noWrap") {
                                +item.name
                            }
                            td("noWrap") {
                                +item.description
                            }
                            td("noWrap"){
/*                                span("fas fa-edit") {
                                    attrs.onClickFunction = {
                                        Store.setMPS(ModelPageState.EDIT)
                                        PhotoService.setChosenId(item.id)
                                        it.stopPropagation()
                                        it.preventDefault()
                                    }
                                }
                                span("fas fa-trash") {
                                    attrs.onClickFunction = {
                                        it.preventDefault()
                                        if(window.confirm(getConfirmMessage(item))){
                                            console.log("delete")
                                            setState {
                                                chosenPhoto = null
                                            }
                                            crScrope.launch {
                                                PhotoService.delete(DeleteREQ(item.id))
                                            }
                                        }else{
                                            console.log("DO NOT DELTE")
                                        }
                                    }
                                }
                                span("fas fa-image") {
                                    attrs.onClickFunction = {
                                        Store.setMPS(ModelPageState.VIEW)
                                        PhotoService.setChosenId(item.id)
                                        it.stopPropagation()
                                        it.preventDefault()
                                    }
                                }*/
                            }
                        }
                    }
                }
            }
        }
    }
}