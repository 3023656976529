package net.ideablender.apofnj.page.gallery

import buildHeader
import net.ideablender.apofnj.dto.GalleryDTO
import net.ideablender.apofnj.pojo.ModelPageState
import net.ideablender.apofnj.store.GalleryService
import net.ideablender.apofnj.store.Store
import react.*
import react.dom.h1


interface PageGalleryState : RState {
    var gallerys:List<GalleryDTO>?
    var modelPageState: ModelPageState
}

@JsExport
class PageGallery() : RComponent<RProps, PageGalleryState>() {
    override fun PageGalleryState.init() {
        gallerys = GalleryService.getAll()
        modelPageState = Store.getMPS()
    }
    override fun componentDidMount() {
        GalleryService.registerCallback("PageGallery", ::onStoreChange)
        Store.registerCallback("PageGallery", ::onStoreChange)
    }

    override fun componentWillUnmount() {
        GalleryService.unRegisterCallback("PageGallery")
        Store.unRegisterCallback("PageGallery")
    }

    private fun onStoreChange(){
        setState {
            gallerys = GalleryService.getAll()
            modelPageState = Store.getMPS()
        }
    }
    override fun RBuilder.render() {
        h1{
            +buildHeader("Galleries", Store.getMPS())
        }
        when(state.modelPageState){
            ModelPageState.DEFAULT -> {
                if(state.gallerys != null){
                    child(GalleryTable::class){
                        attrs.items = state.gallerys
                    }
                }
            }
            ModelPageState.EDIT, ModelPageState.CREATE -> {
                TODO()
            }
            ModelPageState.VIEW -> {
                TODO()
            }
        }
    }
}