package net.ideablender.apofnj.page.quoterequest

import buildHeader
import net.ideablender.apofnj.dto.QuoteRequestDTO
import net.ideablender.apofnj.page.tags.TagDetail
import net.ideablender.apofnj.pojo.ModelPageState
import net.ideablender.apofnj.store.QuoteRequestService
import net.ideablender.apofnj.store.Store
import react.*
import react.dom.h1
import react.dom.p


interface PageQuoteRequestState : RState {
    var quotes:List<QuoteRequestDTO>?
    var modelPageState: ModelPageState
}

@JsExport
class PageQuoteRequests() : RComponent<RProps, PageQuoteRequestState>() {
    override fun PageQuoteRequestState.init() {
        quotes = QuoteRequestService.getAll().sortedBy { it.contactEmail.toLowerCase() }
        modelPageState = Store.getMPS()
    }
    override fun componentDidMount() {
        QuoteRequestService.registerCallback("PageQuoteRequests", ::onStoreChange)
        Store.registerCallback("PageQuoteRequests", ::onStoreChange)
    }

    override fun componentWillUnmount() {
        QuoteRequestService.unRegisterCallback("PageQuoteRequests")
        Store.unRegisterCallback("PageQuoteRequests")
    }

    private fun onStoreChange(){

        setState {
            quotes = QuoteRequestService.getAll().sortedBy { it.contactEmail.toLowerCase() }
            modelPageState = Store.getMPS()
        }
    }

    override fun RBuilder.render() {
        h1{
            +buildHeader("Quote Requests", Store.getMPS())
        }
        when(state.modelPageState){
            ModelPageState.DEFAULT -> {
                if(state.quotes.isNullOrEmpty()){
                    p{
                        +"Waiting for data"
                    }
                }else{
                    child(QuoteRequestTable::class){
                        attrs.items = state.quotes
                    }
                }
            }
            ModelPageState.EDIT, ModelPageState.CREATE -> {
               TODO()
            }
            ModelPageState.VIEW -> {
                child(TagDetail::class){}
            }
        }
    }
}