package net.ideablender.apofnj.req

import kotlinx.serialization.Serializable

@Serializable
data class PasswordResetREQ(
    override var id: Int? = null,
    var uuid:String?,
    var password:String?
    ):REQ {
    override fun getDisplay(): String  = uuid.toString()
}