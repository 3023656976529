package net.ideablender.apofnj.page.photo

import crScrope
import getPathForImage
import kotlinx.browser.window
import kotlinx.coroutines.launch
import kotlinx.html.js.onClickFunction
import net.ideablender.apofnj.common.SortDirection
import net.ideablender.apofnj.common.TableSort
import net.ideablender.apofnj.dto.PhotoDTO
import net.ideablender.apofnj.pojo.ModelPageState
import net.ideablender.apofnj.req.DeleteREQ
import net.ideablender.apofnj.store.PhotoService
import net.ideablender.apofnj.store.Store
import react.*
import react.dom.*

interface PhotoTableState : RState {
    var chosenPhoto:String?
    var sortDirection:SortDirection
    var tableColumn: PhotoTableColumn
    var photos :List<PhotoDTO>?
}

interface PhotoTableProps : RProps {
    var items:List<PhotoDTO>?
}
interface TableColumn{
    val description:String
}

enum class PhotoTableColumn(override val description:String) : TableColumn{
    FILE_NAME("File Name"),
    TITLE("Title"),
    GALLERY("Gallery"),
}

class PhotoTable : RComponent<PhotoTableProps, PhotoTableState>() {
    override fun PhotoTableState.init() {
        sortDirection = SortDirection.DOWN
        tableColumn = PhotoTableColumn.FILE_NAME
    }

    override fun componentDidMount() {
        PhotoService.registerCallback("PhotoTable", ::onStoreChange)
        setState {
            photos = props.items
        }
    }

    override fun componentWillUnmount() {
        PhotoService.unRegisterCallback("PhotoTable")
    }

    private fun onStoreChange(){
        setState {
            photos = PhotoService.getAll()
        }
    }

    private fun onRowClick(item:PhotoDTO, selected: Boolean){
        if(selected){
            setState {
                chosenPhoto = null
            }
        }else{
            setState {
                chosenPhoto = item.fileName
            }
        }
    }

    private fun onTableSortClick(_column:TableColumn, _direction: SortDirection){
        setState {
            sortDirection = _direction
            tableColumn = _column as PhotoTableColumn
            photos = when(state.sortDirection){
                SortDirection.UP -> {
                    when(state.tableColumn){
                        PhotoTableColumn.FILE_NAME -> state.photos!!.sortedBy {
                            it.fileName.toLowerCase()
                        }
                        PhotoTableColumn.TITLE -> state.photos!!.sortedBy { it.title.toLowerCase() }
                        PhotoTableColumn.GALLERY -> state.photos!!.sortedBy { it.gallery.detail.toLowerCase() }
                    }

                }
                SortDirection.DOWN -> {
                    when(state.tableColumn){
                        PhotoTableColumn.FILE_NAME -> state.photos!!.sortedByDescending { it.fileName.toLowerCase() }
                        PhotoTableColumn.TITLE -> state.photos!!.sortedByDescending { it.title.toLowerCase() }
                        PhotoTableColumn.GALLERY -> state.photos!!.sortedByDescending { it.gallery.detail.toLowerCase() }
                    }
                }
            }
        }
    }

    private fun getConfirmMessage(dto: PhotoDTO) = "Are you sure you want to delete photo ${dto.title}?"

    override fun RBuilder.render() {
        table(classes = "ModelTable table table-striped table-bordered table-sm table-hover") {
            thead("thead-dark") {
                tr {
                    th {
                        child(TableSort::class){
                            attrs.tableColumn = PhotoTableColumn.FILE_NAME
                            attrs.onArrowClick = :: onTableSortClick
                            attrs.currColumn = state.tableColumn
                            attrs.currDirection = state.sortDirection
                        }
                    }
                    th {
                        child(TableSort::class){
                            attrs.tableColumn = PhotoTableColumn.TITLE
                            attrs.onArrowClick = :: onTableSortClick
                            attrs.currColumn = state.tableColumn
                            attrs.currDirection = state.sortDirection
                        }
                    }
                    th {
                        child(TableSort::class){
                            attrs.tableColumn = PhotoTableColumn.GALLERY
                            attrs.onArrowClick = :: onTableSortClick
                            attrs.currColumn = state.tableColumn
                            attrs.currDirection = state.sortDirection
                        }
                    }
                    th{}
                }
            }
            tbody {
                if(state.photos != null){
                    state.photos!!.forEach { item ->
                        val selected = state.chosenPhoto == item.fileName
                        val rowClass = if(selected) "rowSelected" else ""
                        tr(classes = rowClass){
                            attrs.onClickFunction = {
                                onRowClick(item, selected)
                            }
                            td("noWrap") {
                                +item.fileName
                            }
                            td("noWrap") {
                                +item.title
                            }
                            td("noWrap") {
                                +item.gallery.detail
                            }
                            td("noWrap"){
                                span("fas fa-edit") {
                                    attrs.onClickFunction = {
                                        Store.setMPS(ModelPageState.EDIT)
                                        PhotoService.setChosenId(item.id)
                                        it.stopPropagation()
                                        it.preventDefault()
                                    }
                                }
                                span("fas fa-trash") {
                                    attrs.onClickFunction = {
                                        it.preventDefault()
                                        if(window.confirm(getConfirmMessage(item))){
                                            console.log("delete")
                                            setState {
                                                chosenPhoto = null
                                            }
                                            crScrope.launch {
                                                PhotoService.delete(DeleteREQ(item.id))
                                            }
                                        }else{
                                            console.log("DO NOT DELTE")
                                        }
                                    }
                                }
                                span("fas fa-image") {
                                    attrs.onClickFunction = {
                                        Store.setMPS(ModelPageState.VIEW)
                                        PhotoService.setChosenId(item.id)
                                        it.stopPropagation()
                                        it.preventDefault()
                                    }
                                }
                            }
                        }
                        if(selected){
                            tr(classes = rowClass){
                                attrs.onClickFunction = {
                                    onRowClick(item, selected)
                                }
                                td{
                                    attrs.colSpan = 4.toString()
                                    img(src = getPathForImage(item), classes = "photoTableImg") {  }
                                    p{
                                        +item.description
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}