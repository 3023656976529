package net.ideablender.apofnj.exception

import net.ideablender.apofnj.dto.DTO
import net.ideablender.apofnj.dto.UserDTO
import net.ideablender.apofnj.pojo.ModelPageState
import net.ideablender.apofnj.req.*
import net.ideablender.apofnj.statics.CLAZZ_USER

object ExText{
    fun getCreateTxt(clazz:String, req: REQ, msg:String)= "Could not create a $clazz from : ${req.toString()}. Reason : $msg."
    fun getUpdateTxt(clazz:String, req: REQ, msg:String)= "Could not update a $clazz from : ${req.toString()}. Reason : $msg."
    fun getDeleteTxt(clazz:String, dto: DTO, msg:String)= "Could not delete a $clazz from : ${dto.toString()}. Reason : $msg."
    
    fun getNotFoundTxt(clazz:String, id:Int)= "Could not find a $clazz with id $id"

}
data class GalleryCreateException(val req: GalleryREQ, val msg: String) : Exception("Could not create a gallery from : ${req.getDisplay()}. Reason : $msg.")
data class GalleryUpdateException(val req: GalleryREQ, val msg: String) : Exception("Could not update a gallery from : ${req.getDisplay()}. Reason : $msg.")
data class GalleryDeleteException(val req: DeleteREQ, val msg: String) : Exception("Could not delete a gallery with id : ${req.getDisplay()}. Reason : $msg.")



data class  ContactFormCreateException(val req:  ContactFormREQ, val msg: String) : Exception("Could not create a  ContactForm from : ${req.getDisplay()}. Reason : $msg.")
data class  ContactFormUpdateException(val req:  ContactFormREQ, val msg: String) : Exception("Could not update a  ContactForm from : ${req.getDisplay()}. Reason : $msg.")
data class  ContactFormDeleteException(val req: DeleteREQ, val msg: String) : Exception("Could not delete a  ContactForm with id : ${req.getDisplay()}. Reason : $msg.")



data class PhotoCreateException(val req: PhotoREQ, val msg: String) : Exception("Could not create a photo from : ${req.getDisplay()}. Reason : $msg.")
data class PhotoUpdateException(val req: PhotoREQ, val msg: String) : Exception("Could not update a photo from : ${req.getDisplay()}. Reason : $msg.")
data class TagAddToAllPhotosException(val req: TagREQ, val msg: String) : Exception("Could not add tag to all photos for : ${req.getDisplay()}. Reason : $msg.")
data class PhotoDeleteException(val req: DeleteREQ, val msg: String) : Exception("Could not delete a photo with id : ${req.getDisplay()}. Reason : $msg.")
data class TagDeleteException(val req: DeleteREQ, val msg: String) : Exception("Could not delete a tag with id : ${req.getDisplay()}. Reason : $msg.")
data class QuoteRequestCreateException(val req: JobREQ, val msg: String) : Exception("Could not create a quote request from : ${req.getDisplay()}. Reason : $msg.")
data class QuoteRequestDeleteException(val req: DeleteREQ, val msg: String) : Exception("Could not delete a quote request with id : ${req.getDisplay()}. Reason : $msg.")



data class TagCreateException(val name: String, val msg: String) : Exception("Could not create a tag from : $name. Reason : $msg.")
class MissingMandatoryFieldException(fieldName: String) :
    Exception("The field $fieldName is mandatory, but was not supplied")
data class GalleryNotFoundException(val id: Int) : Exception("Could not find a Gallery with id : $id")
data class TagNotFoundException(val id: Int) : Exception("Could not find a Tag with id : $id")
data class GalleryNotFoundForNameException(val name: String) : Exception("Could not find a Gallery with name : $name")
data class TagNotFoundForNameException(val name: String) : Exception("Could not find a Tag with name : $name")
data class PhotoNotFoundException(val id: Int) : Exception("Could not find a Photo with id : $id")
data class PhotoFileNotFoundException(val name: String) : Exception("Could not find a Photo with name of  : $name")
data class ThumbnailNotFoundException(val fileName: String) : Exception("Could not find a file with name : $fileName")

data class FailedToDeleteFullGalleryException(val galName: String, val galId:Int) : Exception("Cannot delete gallery $galName with id $galId. It still has children.")
data class DuplicateFileException(val fullPath: String) : Exception("Cannot create full path : $fullPath. It already exists.")
data class FormViewedWrongPageState(val clazz: String, val mps:ModelPageState) : Exception("$clazz should not be seen with page state : $mps")
data class NoChosenEntityForClazz(val clazz: String) : Exception("There is no chosen entity for $clazz.")
data class ReqNotInitializedException(val clazz: String) : Exception("Req for $clazz was not initialized.")


data class UserCreateException(val req: UserREQ, val msg: String) : Exception(ExText.getCreateTxt(
    CLAZZ_USER, req, msg))
data class UserUpdateException(val req: UserREQ, val msg: String) : Exception(ExText.getUpdateTxt(CLAZZ_USER, req, msg))
data class UserDeleteException(val dto: UserDTO, val msg: String) : Exception(ExText.getDeleteTxt(CLAZZ_USER, dto, msg))
data class UserNotFoundException(val id:Int) : Exception(ExText.getNotFoundTxt(CLAZZ_USER, id))
data class UserNotFoundForUuidException(val uuid:String) : Exception("Could not find a user with password reset uuid of : $uuid")
data class UserPasswordResetException(val user:UserDTO) : Exception("Failed to reset password for user: $user")