package net.ideablender.apofnj.dto

import kotlinx.datetime.LocalDateTime
import kotlinx.serialization.Serializable

@Serializable
data class TagDTO(
    override val id: Int,
    @Serializable(with = LocalDateTimeSerializer::class)
    override val dateCreated: LocalDateTime,
    @Serializable(with = LocalDateTimeSerializer::class)
    override val dateUpdated: LocalDateTime,
    val name:String,
    val photos:List<DTOMin>,
) : DTO {
    companion object{
        fun getRestPath(): String = "/tag"
    }
}