package net.ideablender.apofnj.req

import kotlinx.serialization.Serializable

@Serializable
data class TagREQ(
    override var id:Int? = 0,
    var name:String?,
) : REQ {
    override fun getDisplay(): String  = "TagREQ(name:$name)"
}