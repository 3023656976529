package net.ideablender.apofnj.pojo

import net.ideablender.apofnj.statics.VERSION_BUILD
import net.ideablender.apofnj.statics.VERSION_MAJOR
import net.ideablender.apofnj.statics.VERSION_MINOR


/*
val VERSION_MAJOR="000"
val VERSION_MINOR="000"
val VERSION_BUILD="017"
* */
data class AppVersion(
    val versionMajor:String,
    val versionMinor:String,
    val versionBuild:String,
)

fun pullAppVersion() = AppVersion(versionMajor = VERSION_MAJOR, versionMinor = VERSION_MINOR, versionBuild = VERSION_BUILD)
